import React, { useEffect, useMemo, useRef, useState } from "react";
import { Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import SpinLoader from "../../../../components/SpinLoader";
import CustomizeTootip from "../../../../components/CustomizeTootip";
import CustomizeLegend from "../../../../components/CustomizeLegend";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ComposedChart,
  LabelList,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { colors3 } from "../../../../colors/color";
import Printer from "../../../../components/Printer";
import { CSVLink } from "react-csv";
import { saveAs } from "file-saver";
import { exportComponentAsJPEG } from "react-component-export-image";
import {
  convertMonthToNumber,
  endDayToTimeStamps,
  endMonthToTimeStamps,
  endYearToTimeStamps,
  getColourPicker,
  getMonthValue,
  saveToSvg,
  startDayToTimeStamps,
  startMonthToTimeStamps,
  startYearMonthToTimeStamps,
  startYearToTimeStamps,
  yearToTimeStamps,
} from "../../../../constants/Utils";
import "./scbsmb.css";
import Printer2 from "../../../../components/Printer2";
import axios from "axios";
import GraphLoader from "../../../../components/GraphLoader";
import { scbsmbLossMonthlyAction } from "../../../../actions/energyAction";
import SmbLossMonthly from "./SmbLossMonthly";

const SmbLossYearly = ({
  selectedValue,
  setSelectedValue,
  sestSelectedLebel,
  selectedLebel,
  scbsmbDetail,
  databaseStr
}) => {
  const { scbsmbLossMonthly, loading } = useSelector(
    (state) => state.scbsmbLoss
  );

  const [title, setTitle] = useState(null);
  /* ###   Initialize State to animate Graph Bars and Lines   ### */
  const [loader, setLoader] = useState(false);
  const [showEfficiencyYearlyLoss, setShowEfficiencyYearlyLoss] = useState({});

  const [data, setData] = useState(null);
  /* ###   Reference to download graph data in CSV format   ### */

  const downloadRef = useRef(null);

  /* ###   Reference for Graph container TO Download In Jpg and Svg format   ### */

  const graphRef = useRef(null);

  const dispatch = useDispatch();
  const location = useLocation();

  useMemo(() => {
    if (scbsmbLossMonthly && scbsmbLossMonthly?.length > 0) {
      let obj = {};
      Object.keys(scbsmbLossMonthly[0]).forEach((e) => {
        if (e !== "name") {
          obj[e] = true;
        }
      });
      obj["all"] = true;

      setShowEfficiencyYearlyLoss(obj);
    }
  }, [scbsmbLossMonthly]);

  useEffect(() => {
    let { inverter, block, scbsmb } = scbsmbDetail;
    inverter = inverter?.split(" ")[1];
    block = block?.split(" ")[1];
    scbsmb = scbsmb?.split(" ")[1];

    if (scbsmb) {
      setTitle("BL-" + block + " " + "INV-" + inverter + " " + "SCB-" + scbsmb);
    }
    if (
      (!scbsmbLossMonthly && scbsmb) ||
      selectedLebel.endDataValue ||
      scbsmb
    ) {
     
      dispatch(scbsmbLossMonthlyAction(scbsmbDetail, selectedLebel, title,databaseStr));
    }
    if(!scbsmbLossMonthly && databaseStr){
      dispatch(scbsmbLossMonthlyAction(scbsmbDetail, selectedLebel, title,databaseStr));
    }
  }, [dispatch, selectedLebel, scbsmbDetail,databaseStr]);
  return (
    <>
      <CSVLink
        data={
          // scbsmbLossMonthly?.reduce((acc, curr) => {
          //   acc.push({
          //     Month: curr.name,
          //     LossPercentage: curr.loss,
          //   });
          //   return acc;
          // }, []) ||
           []
        }
        filename="data.csv"
        className="hidden"
        target="_blank"
        ref={downloadRef}
      />
      <div
        style={{
          padding: "0.5rem 1.5rem",
          position: "sticky",
          left: "0",
          right: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: "1rem",
          textAlign: "center",
          zIndex: "1",
        }}
      >
        {title ? (
          <h3>SCB Current Average Loss ({title?.endsWith("SCB")?title?.replace("-SCB","-ALL"):title}) </h3>
        ) : (
          <h3>SCB Current Average Loss </h3>
        )}

        <Printer2
          clickhandler={() => downloadRef.current.link.click()}
          jpgDownload={() => {
            document.getElementsByClassName("cpy_right")[0].style.display =
              "block";
            exportComponentAsJPEG(graphRef, { fileName: "graph" });
            document.getElementsByClassName("cpy_right")[0].style.display =
              "none";
          }}
          svgDownload={async () => {
            document.getElementsByClassName("cpy_right")[0].style.display =
              "block";
            const svgData = await saveToSvg(graphRef.current.container);
            document.getElementsByClassName("cpy_right")[0].style.display =
              "none";
            saveAs(svgData, "graph.svg");
          }}
        />
      </div>

      <div style={{
                  minWidth:
                    scbsmbLossMonthly?.length > 0
                      ? scbsmbLossMonthly.length *
                          Object.keys(scbsmbLossMonthly[0]).length *
                          2.8 -
                        5 +
                        "vw"
                      : "100%",
                }} className="scbsmb-responsive-container-1">
        <ResponsiveContainer
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {loading ? (
            <GraphLoader />
          ) : scbsmbLossMonthly ? (
            <ComposedChart
              data={scbsmbLossMonthly || []}
              margin={{
                top: 5,
                right: 5,
                bottom: 5,
                left: 5,
              }}
              barCategoryGap={"5%"}
              ref={graphRef}
            >
              <CartesianGrid stroke="grey" strokeWidth={0.3} />
              <XAxis
                dataKey="name"
                fontSize={"0.8rem"}
                fontWeight={600}
                // tickLine={false}
                // axisLine={false}
                tickMargin={5}
                // padding={{left:10,right:10}}
                minTickGap={10}
              />
              <YAxis
                fontSize={"0.8rem"}
                fontWeight={600}
                width={30}
                domain={[
                  Math.floor(
                    scbsmbLossMonthly?.reduce((acc, curr, index) => {
                      Object.keys(curr).forEach((e) => {
                        if (e !== "name") {
                          acc = Math.min(curr[e], acc);
                        }
                      });
                      return acc;
                    }, Infinity)
                  ) -2,

                  Math.ceil(
                    scbsmbLossMonthly?.reduce((acc, curr, index) => {
                      Object.keys(curr).forEach((e) => {
                        if (e !== "name") {
                          acc = Math.max(curr[e], acc);
                        }
                      });
                      return acc;
                    }, -Infinity)
                  ) + 2,
                ]}
                tickLine={false}
              />
              <Tooltip
                content={
                  <CustomizeTootip
                    active={false}
                    payload={[]}
                    label={""}
                    TooltipValues={
                      scbsmbLossMonthly?.length > 0
                        ? Object.keys(scbsmbLossMonthly[0]).filter(
                            (e) => e !== "name"
                          )
                        : []
                    }
                  />
                }
              />
              <Legend
                content={
                  <CustomizeLegend
                    active={false}
                    payload={[]}
                     LegendValues ={
                      scbsmbLossMonthly?.length > 0
                        ? Object.keys(scbsmbLossMonthly[0]).filter(
                            (e) => e !== "name"
                          )
                        : []
                    }
                    data={showEfficiencyYearlyLoss}
                    setData={setShowEfficiencyYearlyLoss}
                  />
                }
                styles={{
                  fontSize: "1rem",
                }}
              />
              <ReferenceLine
                y={0}
                stroke="#000"
                //  strokeDasharray="3 3"
              />

              {scbsmbLossMonthly?.length > 0 &&
                Object.keys(scbsmbLossMonthly[0]).map((e, index) => {
                  if (e === "name") return null;
                  return (
                    <Bar
                    maxBarSize={30}
                    key={index}
                      hide={showEfficiencyYearlyLoss[e] ? false : true}
                      dataKey={e}
                      fill={`${colors3[0]}`}
                    >
                      <LabelList
                        dataKey={e}
                        position="top"
                        fontSize={"0.7rem"}
                        fontWeight={600}
                        fontFamily="Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;"
                      />
                    </Bar>
                  );
                })}
            </ComposedChart>
          ) : (
            <h3>Data Not Available</h3>
          )}
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default SmbLossYearly;
