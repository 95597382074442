import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./printer.css";

/* ##### Printer Component Which is used to Download Graph  ##### */

const Printer2 = ({ clickhandler, jpgDownload, svgDownload }) => {
  const [open, setOpen] = useState(false);

  window.onclick = function () {
    if (open) setOpen(false);
  };

  return (
    <div
      style={{
        position:"absolute",
        right:"0.2rem",
        zIndex: 1,
      }}
    >
      <div
        className="print_icon_div"
        onClick={(e) => {
          e.stopPropagation();
          setOpen(!open);
        }}
      >
        <i
          style={{
            paddingLeft: "0.3rem",
            marginTop:"0.3rem",
            fontSize: "1.1rem",
            fontWeight: "700",
            cursor: "pointer",
            color: open ? "red" : "black",
          }}
          className="fa-solid fa-bars"
          // className="fa-solid fa-download"
          
        ></i>
      </div>

      {open && (
        <div
          id="hide_content"
          className="links_div"
          onClick={(e) => e.stopPropagation()}
        >
          <Link
            onClick={() => {
              setOpen(false);
              jpgDownload && jpgDownload();
            }}
          >
            Download JPG
          </Link>
          <Link
            onClick={() => {
              setOpen(false);
              svgDownload && svgDownload();
            }}
          >
            Download SVG
          </Link>
          <Link
            onClick={() => {
              setOpen(false);
              clickhandler && clickhandler();
            }}
          >
            Download CSV
          </Link>
        </div>
      )}
    </div>
  );
};

export default Printer2;
