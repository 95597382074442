import React, { Fragment, useEffect, useMemo, useState } from "react";
import "./CountryHeader2.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PageURL from "../../../../constants/PageURL";
import logo from "../../../../fonts and images/newLogo.PNG";
import {
  Box,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from "@mui/material";
import {
  B1_Inv1_smbs,
  B1_Inv2_smbs,
  B2_Inv3_smbs,
  B2_Inv4_smbs,
  headerData,
} from "../../../../constants/Data";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  inverterEfficiencyAction,
  projectDetailAction,
} from "../../../../actions/energyAction";

const CountryHeader3 = (props) => {
  const {
    openSidebar,
    setOpenSidebar,
    selectedValue,
    setSelectedValue,
    scbsmbDetail,
    setscbsmbDetail,
    selectedLebel,
    databaseStr,
  } = props;

  const { user, projectDetail } = useSelector((state) => state.user);
  const data = user?.projectLocation || [];
  const [checkedData, setCheckedData] = React.useState({
    projectDetail: false,
    Diagonistic: false,
    inverterEfficiency: false,
    checkedData: false,
    Prescritive: false,
    string_smb: false,
    correctiveAction: false,
  });

  const [openLinksDetail, setOpenLinksDetail] = useState({
    country: false,
    partnersName: false,
    projectName: false,
    projectDetail: false,
  });
  const [openLinksValue, setOpenLinksValue] = useState({
    country: "None",
    partnersName: "None",
    projectName: "None",
    projectDetail: "None",
  });

  const [openSelect, setOpenSelect] = useState({
    country: false,
    partnersName: false,
    projectName: false,
    projectDetail: false,
  });

  const [projectData, setProjectData] = useState(null);

  // const [projectData, setProjectData] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onclickHandler = (name, trueValue1, trueValue2) => {
    let checked = { ...checkedData };
    for (let key in checked) {
      if (key === name) {
        checked[key] = !checked[key];
      } else {
        checked[key] = false;
      }
    }
    if (trueValue1) {
      checked[trueValue1] = true;
    }
    if (trueValue2) {
      checked[trueValue2] = true;
    }

    setCheckedData(checked);
  };

  const goToPageHandler = (value, path) => {
    setOpenLinksValue({
      ...openLinksValue,
      projectDetail: value,
    });
    setOpenSidebar(false);
    navigate(path);
  };

  window.onresize = () => {
    if (openSidebar && window.innerWidth >= 950) {
      setOpenSidebar(false);
    }
  };

  useMemo(() => {
    if (projectDetail) {
      console.log("hre project detail");
      let obj1 = {},
        obj2 = {};
      let stringArray =
        projectDetail?.plantDetail?.stringDetail?.stringArray || [];

      stringArray.forEach((element) => {
        obj1[element.name] = false;
        element.inverters?.forEach((e) => {
          obj1[element.name + e.name] = false;
        });
        let inverterDetail = projectDetail?.plantDetail?.inverterDetail || [];

        inverterDetail.forEach((element) => {
          obj2[element.name + "Efficiency"] = false;
        });
        setCheckedData({
          ...checkedData,
          ...obj1,
          ...obj2,
        });
        setProjectData(projectDetail?.plantDetail);
      });
    }
  }, [projectDetail]);

  useEffect(() => {
    if (
      selectedValue.country &&
      selectedValue.partnerName &&
      selectedValue.projectName &&
      !projectDetail &&
      databaseStr
    ) {
      dispatch(projectDetailAction(user?.userName, databaseStr));
    }
  }, [dispatch, selectedValue, databaseStr]);

  return (
    <div className="header">
      {/*  ##### GreenEnco Logo Start ##### */}

      <div onClick={() => navigate(PageURL.COUNTRYDEFAULT)}>
        <img src={logo} alt="GreenEnco-Logo" />
      </div>
      {/*  ##### GreenEnco Logo End ##### */}

      <h2>Creating A Better World In A Changing Climate</h2>

      {/*  ##### Code For Header Sidebar In Small Devices Start ##### */}

      <div className="header-open-detail">
        <p
          style={{
            width: "45px",
            height: "45px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <i
            onClick={() => setOpenSidebar((val) => !val)}
            className={`fa-solid ${
              openSidebar ? "fa-bars-staggered" : "fa-bars"
            }`}
          ></i>
        </p>
      </div>
      {/*  */}
      {/*  ##### Code For Header Sidebar In Small Devices End ##### */}

      <div className="sidebar-2" style={{ height: openSidebar ? "60px" : "0" }}>
        <span
          onClick={() =>
            setOpenSelect({ ...openSelect, country: !openSelect.country })
          }
        >
          <FormControl
            variant="filled"
            style={{ color: "white" }}
            sx={{ width: "100%", height: "100%", color: "white" }}
          >
            <InputLabel
              shrink={
                openSelect.country || selectedValue.country !== ""
                  ? true
                  : false
              }
              id="demo-simple-select-filled-label11"
              style={{ color: "white" }}
              sx={{
                height: "100%",
                width: "100%",
                fontWeight: 600,
                fontSize: "2.5vmin",
                letterSpacing: "0.2vmin",
              }}
            >
              Country
            </InputLabel>
            <Select
              open={openSelect.country ? true : false}
              MenuProps={{
                disableScrollLock: true,
              }}
              style={{ color: "white", fontSize: "1.8vmin" }}
              sx={{
                height: "100%",
                width: "100%",
                color: "white",
                letterSpacing: "0.1vmin",
              }}
              labelId="demo-simple-select-filled-label11"
              id="demo-simple-select-filled11"
              defaultValue={""}
              value={selectedValue.country || ""}
              onChange={(e) => {
                setSelectedValue({
                  ...selectedValue,
                  country: e.target.value,
                  partnerName: "",
                  projectName: "",
                  projectDetail: "",
                });

                setCheckedData({
                  ...checkedData,
                  projectDetail: false,
                  Diagonistic: false,
                  Efficiency: false,
                  checkedData: false,
                  Prescritive: false,
                  string_smb: false,
                  correctiveAction: false,
                });
              }}
            >
              {
                <MenuItem value={""}>
                  <em>None</em>{" "}
                </MenuItem>
              }

              {data?.country?.map((value, index) => {
                return (
                  <MenuItem key={index} value={value}>
                    {value}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </span>
        <span
          className="dropdown"
          onClick={() =>
            setOpenSelect({
              ...openSelect,
              partnersName: !openSelect.partnersName,
            })
          }
        >
          <FormControl variant="filled" sx={{ width: "100%", height: "100%" }}>
            <InputLabel
              shrink={
                openSelect.partnersName || selectedValue.partnerName !== ""
                  ? true
                  : false
              }
              id="demo-simple-select-filled-label1"
              style={{ color: "white" }}
              sx={{
                color: "white",
                height: "100%",
                width: "100%",
                fontWeight: 600,
                fontSize: "2.5vmin",
                letterSpacing: "0.2vmin",
              }}
            >
              Partners Name
            </InputLabel>
            <Select
              open={openSelect.partnersName ? true : false}
              MenuProps={{
                disableScrollLock: true,
              }}
              style={{ color: "white", fontSize: "1.8vmin" }}
              sx={{
                height: "100%",
                width: "100%",
                color: "white",
                letterSpacing: "0.1vmin",
              }}
              labelId="demo-simple-select-filled-label1"
              id="demo-simple-select-filled1"
              value={selectedValue.partnerName || ""}
              defaultValue={""}
              onChange={(e) => {
                setSelectedValue({
                  ...selectedValue,
                  partnerName: e.target.value,
                  projectName: "",
                  projectDetail: "",
                });
                setCheckedData({
                  ...checkedData,
                  projectDetail: false,
                  Diagonistic: false,
                  Efficiency: false,
                  checkedData: false,
                  Prescritive: false,
                  string_smb: false,
                  correctiveAction: false,
                });
              }}
            >
              {
                <MenuItem value={""}>
                  <em>None</em>{" "}
                </MenuItem>
              }

              {data.partnersName
                ?.filter((e) => e.country === selectedValue.country)
                ?.map((value, index) => {
                  return (
                    <MenuItem key={index} value={value.partnerName}>
                      {value.partnerName}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </span>
        <span
          className="dropdown"
          onClick={() =>
            setOpenSelect({
              ...openSelect,
              projectName: !openSelect.projectName,
            })
          }
        >
          <FormControl variant="filled" sx={{ width: "100%", height: "100%" }}>
            <InputLabel
              shrink={
                openSelect.projectName || selectedValue.projectName !== ""
                  ? true
                  : false
              }
              id="demo-simple-select-filled-label2"
              style={{ color: "white" }}
              sx={{
                color: "white",
                height: "100%",
                width: "100%",
                fontWeight: 600,
                fontSize: "2.5vmin",
                letterSpacing: "0.2vmin",
              }}
            >
              Project Name
            </InputLabel>
            <Select
              open={openSelect.projectName ? true : false}
              MenuProps={{
                disableScrollLock: true,
              }}
              style={{ color: "white", fontSize: "1.8vmin" }}
              sx={{
                height: "100%",
                width: "100%",
                color: "white",
                letterSpacing: "0.1vmin",
              }}
              labelId="demo-simple-select-filled-label2"
              id="demo-simple-select-filled2"
              onChange={(e) => {
                setSelectedValue({
                  ...selectedValue,
                  projectName: e.target.value,
                  projectDetail: "",
                });
                setCheckedData({
                  ...checkedData,
                  projectDetail: false,
                  Diagonistic: false,
                  Efficiency: false,
                  checkedData: false,
                  Prescritive: false,
                  string_smb: false,
                  correctiveAction: false,
                });
                if (!e.target.value) return;
                dispatch(projectDetailAction(user.userName, databaseStr));
              }}
              value={selectedValue.projectName || ""}
              defaultValue={""}
            >
              {!selectedValue.partnerName && (
                <MenuItem value={""}>
                  <em>None</em>{" "}
                </MenuItem>
              )}

              {data?.projectName
                ?.find(
                  (e) =>
                    e.country === selectedValue.country &&
                    e.partnerName === selectedValue.partnerName
                )
                ?.projectName?.map((value, index) => {
                  return (
                    <MenuItem key={index} value={value.name}>
                      {`${value.name} (${value.capacity})`}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </span>
        <span
          className="dropdown"
          onClick={(e) => {
            setOpenSelect({
              ...openSelect,
              projectDetail: !openSelect.projectDetail,
            });
          }}
        >
          <FormControl variant="filled" sx={{ width: "100%", height: "100%" }}>
            <InputLabel
              shrink={
                openSelect.projectDetail || selectedValue.projectDetail !== ""
                  ? true
                  : false
              }
              id="demo-simple-select-filled-label3"
              style={{ color: "white" }}
              sx={{
                color: "white",
                height: "100%",
                width: "100%",
                fontWeight: 600,
                fontSize: "2.5vmin",
                letterSpacing: "0.2vmin",
              }}
            >
              Project Detail
            </InputLabel>
            <Select
              open={openSelect.projectDetail ? true : false}
              MenuProps={{
                disableScrollLock: true,
              }}
              style={{
                color: "white",
                fontSize: "1.8vmin",
              }}
              sx={{
                height: "100%",
                width: "100%",
                color: "white",
                letterSpacing: "0.1vmin",
              }}
              labelId="demo-simple-select-filled-label3"
              id="demo-simple-select-filled3"
              // onChange={(e) => {
              //   setSelectedValue({
              //     ...selectedValue,
              //     projectDetail: e.target.value,
              //   });
              // }}
              defaultValue={selectedValue.projectDetail || ""}
              value={selectedValue.projectDetail || ""}
              renderValue={() => selectedValue.projectDetail || ""}
            >
              {(!selectedValue.projectName ||
                !projectData?.stringDetail?.stringArray) && (
                <MenuItem value={""}>
                  <em>None</em>{" "}
                </MenuItem>
              )}
              {selectedValue.projectName && (
                <ListSubheader className="SubHeader">
                  <span onClick={() => onclickHandler("projectDetail")}>
                    Overview Details
                    <i
                      className={`fa-solid fa-angle-down ${
                        checkedData.projectDetail ? "active" : ""
                      }`}
                    ></i>
                  </span>
                </ListSubheader>
              )}

              {checkedData.projectDetail &&
                [
                  {
                    url: PageURL.INDIA_PROJ_OVERVIEW,
                    value: "Project OverView",
                  },
                  {
                    url: PageURL.INDIA_GEN_SUMMARY,
                    value: "Generation Summary",
                  },
                ].map((e, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={e.value}
                      onClick={() => {
                        setSelectedValue({
                          ...selectedValue,
                          projectDetail: e.value,
                        });
                        navigate(e.url);
                      }}
                    >
                      {e.value}
                    </MenuItem>
                  );
                })}

              {selectedValue.projectName && (
                <ListSubheader className="SubHeader">
                  <span onClick={() => onclickHandler("Diagonistic")}>
                    Diagonistic Model
                    <i
                      className={`fa-solid fa-angle-down ${
                        checkedData.Diagonistic ? "active" : ""
                      }`}
                    ></i>
                  </span>
                </ListSubheader>
              )}
              {checkedData.Diagonistic &&
                [
                  {
                    url: PageURL.INDIA_DIAGONISTIC_DETAILED,
                    value: "Detailed Summary",
                  },
                  { url: PageURL.INDIA_LOSS_FLOW, value: "Loss Flow Diagram" },
                ].map((e, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={e.value}
                      onClick={() => {
                        setSelectedValue({
                          ...selectedValue,
                          projectDetail: e.value,
                        });
                        navigate(e.url);
                      }}
                    >
                      {e.value}
                    </MenuItem>
                  );
                })}

              {selectedValue.projectName && projectData?.inverterDetail && (
                <ListSubheader className="SubHeader">
                  <span onClick={() => onclickHandler("inverterEfficiency")}>
                    Inverter Efficiency
                    <i
                      className={`fa-solid fa-angle-down ${
                        checkedData.inverterEfficiency ? "active" : ""
                      }`}
                    ></i>
                  </span>
                </ListSubheader>
              )}

              {checkedData.Efficiency && (
                <MenuItem
                  value={"Inverter Efficiency"}
                  onClick={() => {
                    navigate(PageURL.INDIA_INVERTER_EFFICIENCY);
                  }}
                >
                  Inverter Efficiency
                </MenuItem>
              )}
              {selectedValue.projectName &&
                projectData?.inverterDetail &&
                selectedValue.projectName &&
                checkedData.inverterEfficiency &&
                projectData?.inverterDetail?.length > 0 && (
                  <MenuItem
                    value={"All Inverters"}
                    onClick={() => {
                      dispatch(inverterEfficiencyAction({}, selectedLebel));
                      setscbsmbDetail({
                        ...scbsmbDetail,
                        block: null,
                        inverter: null,
                        inverterType: null,
                        scbsmb: null,
                      });
                      setSelectedValue({
                        ...selectedValue,
                        projectDetail: "All Inverters",
                      });
                      navigate(PageURL.INDIA_INVERTER_EFFICIENCY);
                    }}
                  >
                    All Inverters
                  </MenuItem>
                )}
              {selectedValue.projectName &&
                checkedData.inverterEfficiency &&
                projectData?.inverterDetail?.map((e, index) => {
                  return (
                    <Box key={index}>
                      <ListSubheader
                        key={e.name + "Efficiency"}
                        className="SubHeader"
                      >
                        <span
                          onClick={(event) => {
                            event.stopPropagation();

                            onclickHandler(
                              e.name + "Efficiency",
                              "inverterEfficiency"
                            );
                          }}
                        >
                          {" "}
                          {e.name}
                          <i
                            className={`fa-solid fa-angle-down ${
                              checkedData[e.name + "Efficiency"] ? "active" : ""
                            }`}
                          ></i>
                        </span>
                      </ListSubheader>

                      {checkedData[e.name + "Efficiency"] &&
                        e?.inverters?.map((ee, index3) => {
                          return (
                            <MenuItem
                              key={index3}
                              value={ee}
                              onClick={() => {
                                dispatch(
                                  inverterEfficiencyAction(
                                    {
                                      inverter: ee,
                                      block: e.name,
                                      inverterType: e.type,
                                    },
                                    selectedLebel
                                  )
                                );
                                setSelectedValue({
                                  ...selectedValue,
                                  projectDetail: ee,
                                });
                                setscbsmbDetail({
                                  ...scbsmbDetail,
                                  block: e?.name,
                                  inverter: ee,
                                  inverterType: e?.type,
                                  scbsmb: null,
                                });
                                navigate(PageURL.INDIA_INVERTER_EFFICIENCY);
                              }}
                            >
                              {ee}
                            </MenuItem>
                          );
                        })}
                    </Box>
                  );
                })}
              {selectedValue.projectName &&
                projectData?.stringDetail?.stringArray && (
                  <ListSubheader className="SubHeader">
                    <span onClick={(e) => onclickHandler("string_smb")}>
                      SCB/SMB
                      <i
                        className={`fa-solid fa-angle-down ${
                          checkedData.string_smb ? "active" : ""
                        }`}
                      ></i>
                    </span>
                  </ListSubheader>
                )}
              {selectedValue.projectName &&
                checkedData.string_smb &&
                projectData?.stringDetail?.stringArray?.map((e, index1) => {
                  return (
                    <Box key={"SubHeader" + e.name + index1}>
                      {/* First Subheader */}
                      <ListSubheader className="SubHeader">
                        <span
                          onClick={(ee) => {
                            ee.stopPropagation();
                            onclickHandler(e.name, "string_smb");
                          }}
                        >
                          {e.name}
                          <i
                            className={`fa-solid fa-angle-down ${
                              checkedData[e.name] ? "active" : ""
                            }`}
                          ></i>
                        </span>
                      </ListSubheader>

                      {/* Conditionally show inverters if checked */}
                      {checkedData[e.name] &&
                        e?.inverters?.map((ee, index2) => {
                          return (
                            <Box key={"SubHeader2" + ee.name + index1 + index2}>
                              <ListSubheader
                                key={ee.name}
                                className="SubHeader"
                              >
                                <span
                                  onClick={(event) => {
                                    event.stopPropagation();

                                    onclickHandler(
                                      e.name + ee.name,
                                      "string_smb",
                                      e.name
                                    );
                                  }}
                                >
                                  {ee.name}
                                  <i
                                    className={`fa-solid fa-angle-down ${
                                      checkedData[e.name + ee.name]
                                        ? "active"
                                        : ""
                                    }`}
                                  ></i>
                                </span>
                              </ListSubheader>

                              {/* Conditionally show SCBs if checked */}
                              {checkedData[e.name + ee.name] &&
                                ee?.listScbsmb?.map((eee, index3) => {
                                  return (
                                    <MenuItem
                                      key={
                                        e.name +
                                        ee.name +
                                        index1 +
                                        index2 +
                                        index3
                                      }
                                      value={eee}
                                      onClick={() => {
                                        setSelectedValue({
                                          ...selectedValue,
                                          projectDetail: eee,
                                        });

                                        setscbsmbDetail({
                                          ...scbsmbDetail,
                                          block: e?.name,
                                          inverter: ee?.name,
                                          inverterType: ee?.inverterType,
                                          scbsmb: eee,
                                        });
                                        if (
                                          location.pathname !==
                                          PageURL.SCBSMB_LOSS
                                        ) {
                                          navigate(PageURL.SCBSMB_LOSS);
                                        }

                                        // You can navigate to e.url here
                                      }}
                                    >
                                      {eee}
                                    </MenuItem>
                                  );
                                })}
                            </Box>
                          );
                        })}
                    </Box>
                  );
                })}
              {/* {selectedValue.projectName && (
                <ListSubheader className="SubHeader last_item">
                  {" "}
                  <span onClick={() => onclickHandler("Prescritive")}>
                    Prescriptive Model{" "}
                    <i
                      className={`fa-solid fa-angle-down ${
                        checkedData.Prescritive ? "active" : ""
                      }`}
                    ></i>
                   
                  </span>
                </ListSubheader>
              )}
              {checkedData.Prescritive &&
                [
                  {
                    url: PageURL.INDIA_PRES_MODEL_Fault_Tree,
                    value: "Fault Tree Diagram",
                  },
                  {
                    url: PageURL.INDIA_PRES_MODEL_Loss_Flow,
                    value: "Loss Flow Diagram",
                  },
                ].map((e, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={e.url}
                      onClick={() => {
                        navigate(e.url);
                      }}
                    >
                      {e.value}
                    </MenuItem>
                  );
                })} */}
            </Select>
          </FormControl>
        </span>
      </div>

      {/*  ##### Code For Header Sidebar In Large Devices Start ##### */}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          gap: "0vmax",
        }}
        className="detail-link"
      >
        <div className="dropdown">
          <FormControl variant="filled" sx={{ width: "9vmax" }} size="small">
            <InputLabel
              id="demo-simple-select-filled-label"
              style={{ color: "black", fontWeight: 700, fontSize: "0.9vmax" }}
            >
              Country
            </InputLabel>
            <Select
              MenuProps={
                {
                  // disableScrollLock:true,
                }
              }
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={selectedValue.country || ""}
              onChange={(e) => {
                setSelectedValue({
                  ...selectedValue,
                  country: e.target.value,
                  partnerName: "",
                  projectName: "",
                  projectDetail: "",
                });

                setCheckedData({
                  ...checkedData,
                  projectDetail: false,
                  Diagonistic: false,
                  Efficiency: false,
                  checkedData: false,
                  Prescritive: false,
                  string_smb: false,
                  correctiveAction: false,
                });
              }}
              style={{ backgroundColor: "rgb(236, 85, 15)", fontSize: "1vmax" }}
            >
              {/* {!data?.country?.length===0 && (
                <MenuItem value={"None"}>
                  <em>None</em>{" "}
                </MenuItem>
              )} */}

              {
                <MenuItem value={""}>
                  <em>None</em>{" "}
                </MenuItem>
              }

              {data?.country?.map((value, index) => {
                return (
                  <MenuItem key={index} value={value}>
                    {value}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="dropdown">
          <FormControl variant="filled" sx={{ width: "9vmax" }} size="small">
            <InputLabel
              id="demo-simple-select-filled-label1"
              style={{ color: "black", fontWeight: 700, fontSize: "0.9vmax" }}
            >
              Partners Name
            </InputLabel>
            <Select
              MenuProps={
                {
                  // disableScrollLock:true
                }
              }
              labelId="demo-simple-select-filled-label1"
              id="demo-simple-select-filled1"
              onChange={(e) => {
                setSelectedValue({
                  ...selectedValue,
                  partnerName: e.target.value,
                  projectName: "",
                  projectDetail: "",
                });
                setCheckedData({
                  ...checkedData,
                  projectDetail: false,
                  Diagonistic: false,
                  Efficiency: false,
                  checkedData: false,
                  Prescritive: false,
                  string_smb: false,
                  correctiveAction: false,
                });
              }}
              value={selectedValue.partnerName || ""}
              style={{ backgroundColor: "rgb(236, 85, 15)", fontSize: "1vmax" }}
            >
              {/* {!country && (
                <MenuItem value={"None"}>
                  <em>None</em>{" "}
                </MenuItem>
              )} */}

              {
                <MenuItem value={""}>
                  <em>None</em>{" "}
                </MenuItem>
              }

              {data.partnersName
                ?.filter((e) => e.country === selectedValue.country)
                ?.map((value, index) => {
                  return (
                    <MenuItem key={index} value={value.partnerName}>
                      {value.partnerName}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </div>
        <div className="dropdown">
          <FormControl variant="filled" sx={{ width: "9vmax" }} size="small">
            <InputLabel
              id="demo-simple-select-filled-label2"
              style={{ color: "black", fontWeight: 700, fontSize: "0.9vmax" }}
            >
              Project Name
            </InputLabel>
            <Select
              MenuProps={
                {
                  // disableScrollLock:true
                }
              }
              labelId="demo-simple-select-filled-label2"
              id="demo-simple-select-filled2"
              onChange={(e) => {
                setSelectedValue({
                  ...selectedValue,
                  projectName: e.target.value,
                  projectDetail: "",
                });
                setCheckedData({
                  ...checkedData,
                  projectDetail: false,
                  Diagonistic: false,
                  Efficiency: false,
                  checkedData: false,
                  Prescritive: false,
                  string_smb: false,
                  correctiveAction: false,
                });
                if (!e.target.value) return;
                // dispatch(
                //   projectDetailAction(
                //     user.userName,
                //     databaseStr
                //   )
                // );
              }}
              value={selectedValue.projectName || ""}
              style={{ backgroundColor: "rgb(236, 85, 15)", fontSize: "1vmax" }}
            >
              {/* {
                !partnersName &&  <MenuItem value={"None"}>
                <em>None</em>{" "}
              </MenuItem>
              } */}

              {!selectedValue.partnerName && (
                <MenuItem value={""}>
                  <em>None</em>{" "}
                </MenuItem>
              )}

              {data?.projectName
                ?.find(
                  (e) =>
                    e.country === selectedValue.country &&
                    e.partnerName === selectedValue.partnerName
                )
                ?.projectName?.map((value, index) => {
                  return (
                    <MenuItem key={index} value={value.name}>
                      {`${value.name} (${value.capacity})`}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </div>

        <div className="dropdown">
          <FormControl variant="filled" sx={{ width: "9vmax" }} size="small">
            <InputLabel
              id="demo-simple-select-filled-label3"
              style={{ color: "black", fontWeight: 700, fontSize: "0.9vmax" }}
            >
              Project Detail
            </InputLabel>
            <Select
              MenuProps={
                {
                  // disableScrollLock:true
                }
              }
              labelId="demo-simple-select-filled-label3"
              id="demo-simple-select-filled3"
              // onChange={(e) => {
              //   setSelectedValue({
              //     ...selectedValue,
              //     projectDetail: e.target.value,
              //   });
              // }}
              defaultValue={selectedValue.projectDetail || ""}
              value={selectedValue.projectDetail || ""}
              renderValue={() => selectedValue.projectDetail || ""}
              style={{ backgroundColor: "rgb(236, 85, 15)", fontSize: "1vmax" }}
            >
              {(!selectedValue.projectName ||
                !projectData?.stringDetail?.stringArray) && (
                <MenuItem value={""}>
                  <em>None</em>{" "}
                </MenuItem>
              )}
              {selectedValue.projectName && (
                <ListSubheader className="SubHeader">
                  <span onClick={() => onclickHandler("projectDetail")}>
                    Overview Details
                    <i
                      className={`fa-solid fa-angle-down ${
                        checkedData.projectDetail ? "active" : ""
                      }`}
                    ></i>
                  </span>
                </ListSubheader>
              )}
              {checkedData.projectDetail &&
                [
                  {
                    url: PageURL.INDIA_PROJ_OVERVIEW,
                    value: "Project OverView",
                  },

                  {
                    url: PageURL.INDIA_GEN_SUMMARY,
                    value: "Generation Summary",
                  },
                ].map((e, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={e.value}
                      onClick={() => {
                        setSelectedValue({
                          ...selectedValue,
                          projectDetail: e.value,
                        });
                        navigate(e.url);
                      }}
                    >
                      {e.value}
                    </MenuItem>
                  );
                })}
              {selectedValue.projectName && (
                <ListSubheader className="SubHeader">
                  <span onClick={() => onclickHandler("Diagonistic")}>
                    Diagonistic Model
                    <i
                      className={`fa-solid fa-angle-down ${
                        checkedData.Diagonistic ? "active" : ""
                      }`}
                    ></i>
                  </span>
                </ListSubheader>
              )}
              {checkedData.Diagonistic &&
                [
                  {
                    url: PageURL.INDIA_DIAGONISTIC_DETAILED,
                    value: "Detailed Summary",
                  },
                  { url: PageURL.INDIA_LOSS_FLOW, value: "Loss Flow Diagram" },
                ].map((e, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={e.value}
                      onClick={() => {
                        setSelectedValue({
                          ...selectedValue,
                          projectDetail: e.value,
                        });
                        navigate(e.url);
                      }}
                    >
                      {e.value}
                    </MenuItem>
                  );
                })}
              {selectedValue.projectName && projectData?.inverterDetail && (
                <ListSubheader className="SubHeader">
                  <span onClick={() => onclickHandler("inverterEfficiency")}>
                    Inverter Efficiency
                    <i
                      className={`fa-solid fa-angle-down ${
                        checkedData.inverterEfficiency ? "active" : ""
                      }`}
                    ></i>
                  </span>
                </ListSubheader>
              )}
              {selectedValue.projectName &&
                projectData?.inverterDetail &&
                selectedValue.projectName &&
                checkedData.inverterEfficiency &&
                projectData?.inverterDetail?.length > 0 && (
                  <MenuItem
                    value={"All Inverters"}
                    onClick={() => {
                      dispatch(inverterEfficiencyAction({}, selectedLebel));
                      setscbsmbDetail({
                        ...scbsmbDetail,
                        block: null,
                        inverter: null,
                        inverterType: null,
                        scbsmb: null,
                      });
                      setSelectedValue({
                        ...selectedValue,
                        projectDetail: "All Inverters",
                      });
                      navigate(PageURL.INDIA_INVERTER_EFFICIENCY);
                    }}
                  >
                    All Inverters
                  </MenuItem>
                )}
              {selectedValue.projectName &&
                checkedData.inverterEfficiency &&
                projectData?.inverterDetail?.map((e, index) => {
                  return (
                    <Box key={index}>
                      <ListSubheader
                        key={e.name + "Efficiency"}
                        className="SubHeader"
                      >
                        <span
                          onClick={(event) => {
                            event.stopPropagation();

                            onclickHandler(
                              e.name + "Efficiency",
                              "inverterEfficiency"
                            );
                          }}
                        >
                          {" "}
                          {e.name}
                          <i
                            className={`fa-solid fa-angle-down ${
                              checkedData[e.name + "Efficiency"] ? "active" : ""
                            }`}
                          ></i>
                        </span>
                      </ListSubheader>

                      {checkedData[e.name + "Efficiency"] &&
                        e?.inverters?.map((ee, index3) => {
                          return (
                            <MenuItem
                              key={index3}
                              value={ee}
                              onClick={() => {
                                dispatch(
                                  inverterEfficiencyAction(
                                    {
                                      inverter: ee,
                                      block: e.name,
                                      inverterType: e.type,
                                    },
                                    selectedLebel
                                  )
                                );
                                setSelectedValue({
                                  ...selectedValue,
                                  projectDetail: ee,
                                });
                                setscbsmbDetail({
                                  ...scbsmbDetail,
                                  block: e?.name,
                                  inverter: ee,
                                  inverterType: e?.type,
                                  scbsmb: null,
                                });
                                navigate(PageURL.INDIA_INVERTER_EFFICIENCY);
                              }}
                            >
                              {ee}
                            </MenuItem>
                          );
                        })}
                    </Box>
                  );
                })}

              {selectedValue.projectName &&
                projectData?.stringDetail?.stringArray && (
                  <ListSubheader className="SubHeader">
                    <span onClick={(e) => onclickHandler("string_smb")}>
                      SCB/SMB
                      <i
                        className={`fa-solid fa-angle-down ${
                          checkedData.string_smb ? "active" : ""
                        }`}
                      ></i>
                    </span>
                  </ListSubheader>
                )}
              {selectedValue.projectName &&
                checkedData.string_smb &&
                projectData?.stringDetail?.stringArray?.map((e, index1) => {
                  return (
                    <Box key={"SubHeader" + e.name + index1}>
                      {/* First Subheader */}
                      <ListSubheader className="SubHeader">
                        <span
                          onClick={(ee) => {
                            ee.stopPropagation();
                            onclickHandler(e.name, "string_smb");
                          }}
                        >
                          {e.name}
                          <i
                            className={`fa-solid fa-angle-down ${
                              checkedData[e.name] ? "active" : ""
                            }`}
                          ></i>
                        </span>
                      </ListSubheader>

                      {/* Conditionally show inverters if checked */}
                      {checkedData[e.name] &&
                        e?.inverters?.map((ee, index2) => {
                          return (
                            <Box key={"SubHeader2" + ee.name + index1 + index2}>
                              <ListSubheader
                                key={ee.name}
                                className="SubHeader"
                              >
                                <span
                                  onClick={(event) => {
                                    console.log("clicking here2");

                                    event.stopPropagation();

                                    onclickHandler(
                                      e.name + ee.name,
                                      "string_smb",
                                      e.name
                                    );
                                  }}
                                >
                                  {ee.name}
                                  <i
                                    className={`fa-solid fa-angle-down ${
                                      checkedData[e.name + ee.name]
                                        ? "active"
                                        : ""
                                    }`}
                                  ></i>
                                </span>
                              </ListSubheader>

                              {/* Conditionally show SCBs if checked */}
                              {checkedData[e.name + ee.name] &&
                                ee?.listScbsmb?.map((eee, index3) => {
                                  return (
                                    <MenuItem
                                      key={
                                        e.name +
                                        ee.name +
                                        index1 +
                                        index2 +
                                        index3
                                      }
                                      value={eee}
                                      onClick={() => {
                                        // onclickHandler(
                                        //   e.name + ee.name,
                                        //   "string_smb",
                                        //   e.name
                                        // );
                                        console.log("clicking here1");
                                        setSelectedValue({
                                          ...selectedValue,
                                          projectDetail: eee,
                                        });

                                        setscbsmbDetail({
                                          ...scbsmbDetail,
                                          block: e?.name,
                                          inverter: ee?.name,
                                          inverterType: ee?.inverterType,
                                          scbsmb: eee,
                                        });
                                        if (
                                          location.pathname !==
                                          PageURL.SCBSMB_LOSS
                                        ) {
                                          navigate(PageURL.SCBSMB_LOSS);
                                        }

                                        // You can navigate to e.url here
                                      }}
                                    >
                                      {eee}
                                    </MenuItem>
                                  );
                                })}
                            </Box>
                          );
                        })}
                    </Box>
                  );
                })}

              {/* {selectedValue.projectName && (
                <ListSubheader className="SubHeader last_item">
                  {" "}
                  <span onClick={() => onclickHandler("Prescritive")}>
                    Prescriptive Model{" "}
                    <i
                      className={`fa-solid fa-angle-down ${
                        checkedData.Prescritive ? "active" : ""
                      }`}
                    ></i>
                   
                  </span>
                </ListSubheader>
              )}
              {checkedData.Prescritive &&
                [
                  {
                    url: PageURL.INDIA_PRES_MODEL_Fault_Tree,
                    value: "Fault Tree Diagram",
                  },
                  {
                    url: PageURL.INDIA_PRES_MODEL_Loss_Flow,
                    value: "Loss Flow Diagram",
                  },
                ].map((e, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={e.url}
                      onClick={() => {
                        navigate(e.url);
                      }}
                    >
                      {e.value}
                    </MenuItem>
                  );
                })} */}
            </Select>
          </FormControl>
        </div>
      </div>

      {/*  ##### Code For Header Sidebar In Large Devices End ##### */}
    </div>
  );
};

export default CountryHeader3;
