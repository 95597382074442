import React from "react";
import ReactSpeedometer from "react-d3-speedometer";

/* Gauge Meter Component  used in Generation Summery component */

const SpeedChart = (props) => {
  return (
    // <div style={{height:"100%",width:"100%", display: "flex", flexDirection: "column",alignItems:"center",justifyContent:"center",gap:"0.5rem"}}>
    <div className="speed-chart-wrapper">
      <ReactSpeedometer
        maxValue={props?.maxValue || 0}
        minValue={props?.minValue || 0}
        value={props?.value || 0}
        segments={props?.segments}
        segmentColors={[
          "#00D2FC",
          "#00E1ED",
          "#22EED1",
          "#78F6AD",
          "#B9FA8A",
          "#F9F871",
        ]}
        needleTransition="easeSinIn"
        ringWidth={70}
        fluidWidth={true}
      />{" "}
      <h3 style={{ textAlign: "center" }}>{props?.title}</h3>
    </div>

    // </div>
  );
};

export default SpeedChart;
