// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inverter-efficiency-responsive-container {
  height: 60vh;
  width: 100%;
  min-width: 500px;
  position: relative;
  
}

@media screen and (max-width: 550px) {
  .inverter-efficiency-responsive-container {
    height: 50vh;
  }
}
`, "",{"version":3,"sources":["webpack://./src/modules/countries/components/inverterEfficiency/inverterEfficiency.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,gBAAgB;EAChB,kBAAkB;;AAEpB;;AAEA;EACE;IACE,YAAY;EACd;AACF","sourcesContent":[".inverter-efficiency-responsive-container {\r\n  height: 60vh;\r\n  width: 100%;\r\n  min-width: 500px;\r\n  position: relative;\r\n  \r\n}\r\n\r\n@media screen and (max-width: 550px) {\r\n  .inverter-efficiency-responsive-container {\r\n    height: 50vh;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
