import React from "react";
import "./Login.css";
import Buttons from "../../../components/ButtonsComp";
import "./Login.css";
import "react-toastify/dist/ReactToastify.css";

import { useNavigate } from "react-router-dom";
import PageURL from "../../../constants/PageURL";

/* ##### Login/Signup Component To Render Login/Signup UI  ##### */

const Login = ({ children }) => {
  const navigate = useNavigate();
  return (
    <div className="login-page">
      <div onClick={() => navigate(PageURL.BASE)} style={{ cursor: "pointer", display: "flex", color: "white", alignItems: "center", flexDirection: "column", gap: "0", justifyContent: "center", fontFamily: "Georgia Ref Regular" }}>
        <h3 style={{ fontSize: "6vmin", letterSpacing: "0.1vmin", fontWeight: "200", lineHeight: "6vmin" }}>GreenEnco</h3>
        <span style={{ fontSize: "2vmin", fontWeight: "500", letterSpacing: "0.1vmin" }}>Maximizing Asset Values</span>
      </div>
      <div className="login">
        <div className="text-heading">
          <div className="container">
            <span className="text1" style={{ fontSize: "7vmax" }}>
              W<span className="text1">el</span><span className="text3">c</span><span className="text1">ome </span>
            </span>
            <span className="text2">To SOLAR pvAPM Dashboard </span>

            <span style={{ fontWeight: "bolder", textAlign: "center" }}>
              Please Login/ Sign Up to Continue.
            </span>
            <div style={{ marginTop: "1vmax" }}>
              <Buttons
                type="contained"
                buttonType="primary"
                buttonText="Login"
                onClick={() => {
                  navigate(PageURL.BASE)
                }}
              ></Buttons>
              <Buttons
                type="outlined"
                buttonType="secondary"
                buttonText={`Sign Up`}
                onClick={() => {
                  navigate(PageURL.SIGN_UP)
                }}
              ></Buttons>
            </div>
          </div>
        </div>
        <div className="login-container">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Login;
