import React, { useEffect, useRef, useState } from "react";
import {
  Area,
  AreaChart,
  Bar,
  CartesianGrid,
  ComposedChart,
  LabelList,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { saveAs } from "file-saver";
import { colors1, colors3 } from "../../../../colors/color";
import CustomizeTootip from "../../../../components/CustomizeTootip";
import CustomizeLegend from "../../../../components/CustomizeLegend";
import Printer2 from "../../../../components/Printer2";
import { exportComponentAsJPEG } from "react-component-export-image";
import { saveToSvg } from "../../../../constants/Utils";
import { CSVLink } from "react-csv";
import GraphLoader from "../../../../components/GraphLoader";
const PvsystActualGTI = ({ GHIGTI, loading }) => {
  const csvDownloadRef = useRef(null);
  const graphRef = useRef(null);
  const [showBarData, setShowBarData] = useState({});
  const [data, setData] = useState(null);

  useEffect(() => {
    if (GHIGTI && GHIGTI?.length > 0) {
      let result = [];
      let obj = {},
        obj2 = {};

      GHIGTI?.forEach((e) => {
        obj2 = {};
        obj2["name"] = e["name"];
        obj2["GII (KWh/m^2)"] = e["GII (KWh/m^2)"]?.toFixed(2);
        obj2["Actual GII (kWh/m^2)"] = e["Actual GII (kWh/m^2)"]?.toFixed(2);
        obj2[`Pvsyst GII Vs Actual GII`] =
          e[`Pvsyst GII Vs Actual GII`]?.toFixed(2);
        result.push(obj2);
      });
      Object.keys(GHIGTI[0]).forEach((e) => {
        if (
          e === `Pvsyst GII Vs Actual GII` ||
          e === "GII (KWh/m^2)" ||
          e === "Actual GII (kWh/m^2)"
        ) {
          obj[e] = true;
        }
      });
      setData(result);
      obj["all"] = true;
      setShowBarData(obj);
    }
  }, [GHIGTI]);

  return (
    <>
      <div
        style={{
          padding: "0.5rem 1.5rem",
          position: "sticky",
          left: "0",
          right: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          zIndex: "1",
        }}
      >
        <h5>
          PVsyst GTI (kWh/m^2) vs Actual GTI (kWh/m^2) vs PVsyst Vs Actual GTI
          (kWh/m^2)
        </h5>
        <Printer2
          clickhandler={() => csvDownloadRef.current.link.click()}
          jpgDownload={() =>
            setTimeout(async () => {
              document.getElementsByClassName("cpy_right")[0].style.display =
                "block";
              exportComponentAsJPEG(graphRef, {
                fileName: "graph",
              });
              document.getElementsByClassName("cpy_right")[0].style.display =
                "none";
            }, 100)
          }
          svgDownload={async () => {
            document.getElementsByClassName("cpy_right")[0].style.display =
              "block";
            const svgData = await saveToSvg(graphRef.current.container);
            document.getElementsByClassName("cpy_right")[0].style.display =
              "none";

            saveAs(svgData, "graph.svg");
          }}
        />
      </div>
      <CSVLink
        data={
          [].reduce((acc, curr) => {
            acc.push({
              Month: curr.name,
              PVsystGTI: curr["GII (KWh/m^2)"],
              ActualGTI: curr["Actual GII (kWh/m^2)"],
              PVsystGTIvsActualGTI: curr["Pvsyst GII Vs Actual GII"],
            });
            return acc;
          }, []) || []
        }
        filename="data.csv"
        className="hidden"
        target="_blank"
        ref={csvDownloadRef}
      />

      <div
        style={{
          minWidth:
            GHIGTI?.length > 0
              ? GHIGTI.length * Object.keys(GHIGTI[0]).length * 0.8 - 5 + "vw"
              : "100%",
        }}
        className="detailed-responsive-container-1"
      >
        <ResponsiveContainer>
          {loading ? (
            <div
              style={{
                position: "absolute",
                top: "0",
                bottom: "0",
                right: "0",
                left: "0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <GraphLoader />
            </div>
          ) : (
            <ComposedChart
              data={data || []}
              margin={{
                top: 5,
                right: 5,
                bottom: 5,
                left: 5,
              }}
              barCategoryGap={"20%"}
              ref={graphRef}
            >
              <CartesianGrid stroke="grey" strokeWidth={0.3} />
              <XAxis
                dataKey="name"
                fontSize={"0.8rem"}
                fontWeight={600}
                tickLine={false}
                axisLine={false}
                tickMargin={5}
              />
              <YAxis
                fontSize={"0.8rem"}
                fontWeight={600}
                tickLine={false}
                tickMargin={5}
                tickFormatter={(v) =>
                  v >= 1000 ? parseFloat(v / 1000).toFixed(0) + "k" : v
                }
                yAxisId="left-axis"
                //  label={{ value: "Energy", angle: -90,x:20, position: "insideLeft"}}
                // label={<AxisLabel axisType='yAxis' x={-20} y={-5}>PVsyst GTI & Actual GTI</AxisLabel>}
                label={{
                  value: "PVsyst GTI & Actual GTI",
                  angle: -90,
                  position: "insideBottomLeft",
                  fontSize: "0.8rem",
                  fontWeight: 600,
                }}
                // name: 'Jan-07',
                // 'GII (KWh/m^2)': 4.71,
                // 'Actual GHI (kWh/m^2)': 1.7,
                // 'Actual GII (kWh/m^2)': 1.88,
                // 'Pvsyst GII Vs Actual GII': -60,
                // 'Pvsyst GHI Vs Actual GHI': -53.9

                domain={[
                  Math.floor(
                    data?.reduce(
                      (acc, curr, index) =>
                        Math.min(
                          curr["GII (KWh/m^2)"],
                          curr["Actual GII (kWh/m^2)"],
                          acc
                        ),
                      Infinity
                    )
                  ),
                  Math.ceil(
                    data?.reduce(
                      (acc, curr, index) =>
                        Math.max(
                          curr["GII (KWh/m^2)"],
                          curr["Actual GII (kWh/m^2)"],
                          acc
                        ),
                      -Infinity
                    )
                  ),
                ]}
              />
              <YAxis
                fontSize={"0.8rem"}
                fontWeight={600}
                tickLine={false}
                tickMargin={5}
                yAxisId="right-axis"
                orientation="right"
                // label={<AxisLabel axisType='yAxis' x={30} y={300}>PVsyst Vs Actual GTI</AxisLabel>}
                label={{
                  value: "PVsyst GTI Vs Actual GTI",
                  angle: 90,
                  // transform: "translateX(1)",
                  position: "insideBottomRight",
                  fontSize: "0.8rem",
                  // dy: -160,
                  fontWeight: 600,
                }}
                domain={[
                  Math.floor(
                    data?.reduce(
                      (acc, curr, index) =>
                        Math.min(curr["Pvsyst GII Vs Actual GII"], acc),
                      Infinity
                    )
                  ) - 2,
                  Math.ceil(
                    data?.reduce(
                      (acc, curr, index) =>
                        Math.max(curr["Pvsyst GII Vs Actual GII"], acc),
                      -Infinity
                    )
                  ) + 2,
                ]}
              />
              <Tooltip
                content={
                  <CustomizeTootip
                    active={false}
                    payload={[]}
                    label={""}
                    TooltipValues={[
                      "PVsyst GTI",
                      "Actual GTI",
                      "PVsyst GTI vs Actual GTI",
                    ]}
                  />
                }
              />
              <Legend
                content={
                  <CustomizeLegend
                    active={false}
                    payload={[]}
                    LegendValues={[
                      "PVsyst GTI",
                      "Actual GTI",
                      "PVsyst GTI vs Actual GTI",
                    ]}
                    data={showBarData}
                    setData={setShowBarData}
                    styles={{ fontSize: "0.8rem" }}
                  />
                }
              />

              {/* {data?.length > 0 &&
                Object.keys(data[0])?.map((e, index) => {
                  if (e === "name") {
                    return null;
                  }
                  if (index == 3) {
                    return ( */}

              {/* );
                  } */}

              {/* return (
                    <> */}
              <Bar
                hide={showBarData['GII (KWh/m^2)'] ? false : true}
                dataKey={'GII (KWh/m^2)'}
                fill={`${colors3[0]}`}
                yAxisId="left-axis"
              />

              <Bar
                hide={showBarData['Actual GII (kWh/m^2)'] ? false : true}
                dataKey={'Actual GII (kWh/m^2)'}
                fill={`${colors3[1]}`}
                yAxisId="left-axis"
              />

              <Line
                hide={showBarData['Pvsyst GII Vs Actual GII'] ? false : true}
                type="monotone"
                dataKey={'Pvsyst GII Vs Actual GII'}
                strokeWidth={3}
                stroke={`${colors1[4]}`}
                yAxisId="right-axis"
              />
              {/* </>
                  );
                })} */}
            </ComposedChart>
          )}
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default PvsystActualGTI;
