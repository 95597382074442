import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { thunk } from "redux-thunk"
import { composeWithDevTools } from 'redux-devtools-extension'
import { GHI_GTI_data, all_scbsmb, efficiency, heatmapData, inverterSmbMonthlyLoss, inverterSmbYearlyLoss, monthly_inverter_efficiency, normalizedEnergyDetails, plantDetail } from '../reducers/inverterReducers'
import { allow_user, registred_users, user } from '../reducers/userReducers'

/* ##### Creating Reducer using Combine Reducer  ##### */

const reducer = combineReducers({
  efficiency: efficiency,
  all_scbsmb: all_scbsmb,
  monthly_inverter_efficiency: monthly_inverter_efficiency,
  energy: normalizedEnergyDetails,
  GHI_GTI_data: GHI_GTI_data,
  user: user,
  registred_users: registred_users,
  allow_user: allow_user,
  plantDetail: plantDetail,
  inverterSmbYearlyLoss,
  inverterSmbMonthlyLoss,
  heatmapData
})

/* #####  To Initialize Our Store Values to undefined When Dispathed an Action of Type "USER_LOGOUT"  ##### */

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    return reducer(undefined, action)
  }
  return reducer(state, action)
}

const middleware = [thunk];
let initialState = {};

/* #####  Applying Middleware (Thunk) and Creating Redux Store  ##### */

// const store = createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))
const store = createStore(rootReducer, initialState,applyMiddleware(...middleware))


export default store;


