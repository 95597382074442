import React, { useEffect, useRef, useState } from "react";
import "./GenOverView.css";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import SpeedChart from "../../../../components/SpeedChart";
import { useDispatch, useSelector } from "react-redux";
import SpinLoader from "../../../../components/SpinLoader";
import SelectOptions from "./SelectOptions";
import Printer from "../../../../components/Printer";
import { CSVLink } from "react-csv";
import { saveAs } from "file-saver";
import {
  Area,
  AreaChart,
  Bar,
  CartesianGrid,
  ComposedChart,
  LabelList,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import CustomizeTootip from "../../../../components/CustomizeTootip";
import CustomizeLegend from "../../../../components/CustomizeLegend";
import { colors1, colors3 } from "../../../../colors/color";
import { exportComponentAsJPEG } from "react-component-export-image";
import { convertToReadableValue, saveToSvg } from "../../../../constants/Utils";
import Printer2 from "../../../../components/Printer2";

import DateRangeComp from "../../../../components/DateRangeComp";
import NetContractual from "./NetContractual";
import ExcessShortFall from "./ExcessShortFall";
import ActualPrACLineLoss from "./ActualPRACLineLoss";
import NetContractualShortFall from "./NetContractualShortFall";
import AcutalPR from "./AcutalPR";
import { json, useLocation, useNavigate } from "react-router-dom";
import YourComponent from "../Headers/YourComponent";
import YourComponent2 from "../Headers/YourComponent2";
import {
  acLineLossActualPrAction,
  netContractualEnergyShortfallAction,
} from "../../../../actions/energyAction";
import GraphLoader from "../../../../components/GraphLoader";

const GenOverView = ({
  selectedLebel,
  scbsmbDetail,
  setscbsmbDetail,
  databaseStr,
}) => {
  // const { loading, energy } = useSelector((state) => state.energy);
  const energy = {
    data1: [],
    data2: [],
  };

  const { netContractualEnergyShortfall, loading } = useSelector(
    (state) => state.netContractualEnergyShortfall
  );
  const { acLineLossActualPr, loading2 } = useSelector(
    (state) => state.acLineLossActualPr
  );

  const [dateTypeValue, setDateTypeValue] = useState({
    yearType: "",
    startYear: "",
    endYear: "",
    startMonth: "",
    endMonth: "",
    date: "",
  });
  const [openDateModal, setOpenModal] = useState({
    type: false,
    date: false,
  });

  /* ###   Initialize month for Gaugemeter Default value   ### */

  const [initialMonth, setInitialMonth] = useState({
    month1: "January",
    month2: "January",
    month3: "January",
  });

  /* ###   Initialize State to animate progress bar   ### */

  const [progressValueAnimate, setProgressValueAnimate] = useState({
    progress1: false,
    progress2: false,
    progress3: false,
  });

  /* ###   Initialize State to animate Graph Bars and Lines   ### */

  const [showPRData, setShowPRData] = useState({ showPR: true, all: true });
  const [showEnergyData, setShowEnergyData] = useState({
    netEnergy: true,
    contractualEnergy: true,
    shortFall: true,
    all: true,
  });

  // console.log(serachParams.toString())

  /* ###   Reference for Graph container TO Download In Jpg and Svg format   ### */

  const speedometerRef3 = useRef(null);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (selectedLebel.endDataValue && databaseStr) {
      dispatch(netContractualEnergyShortfallAction(selectedLebel, databaseStr));
      dispatch(acLineLossActualPrAction(selectedLebel, databaseStr));
    }
    if (!netContractualEnergyShortfall && databaseStr) {
      dispatch(netContractualEnergyShortfallAction(selectedLebel, databaseStr));
    }

    if (!acLineLossActualPr && databaseStr) {
      dispatch(acLineLossActualPrAction(selectedLebel, databaseStr));
    }
  }, [dispatch, selectedLebel, databaseStr]);
  return (
    <>
      {/* <YourComponent/> */}
      {/* <YourComponent2/> */}
      {
        // loading ? (
        //   <SpinLoader />
        // ) :
        <>
          {/* {energy?.data1 && energy?.data2 && ( */}
          <div>
            <Grid container xl={12} textAlign={"center"}>
              {/* ###   GenOverview Container 1 Start   ### */}
              <Grid
                sx={{ boxShadow: 2 }}
                container
                xs={11.8}
                columnGap={"1vmax"}
                rowGap={"1vmax"}
                style={{
                  boxSizing: "border-box",
                  borderWidth: "3px",
                  // borderRadius: "8px",
                  margin: "0 auto 1vmax",
                  padding: "1vmax",
                  background:
                    "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
                }}
                justifyContent={"space-between"}
                className="generation-overview-container"
              >
                <Grid
                  item
                  lg={5.5}
                  md={12}
                  sm={12}
                  xs={12}
                  className="gen-overview-1"
                >

{loading ? (
                <div style={{ height: "100%", width: "100%" }}>
                  <GraphLoader />
                </div>
              ) : (
                <>
                  <div>
                    <div>
                      <div>
                        <progress
                          className="progress_1"
                          style={{
                            accentColor: "green",
                            borderRadius: "14px",
                            border: progressValueAnimate.progress1
                              ? "3px solid red"
                              : "none",
                          }}
                          value={netContractualEnergyShortfall?.reduce(
                            (acc, curr) => acc + Number(curr["netEnergy"]),
                            0
                          )}
                          max={
                            netContractualEnergyShortfall?.reduce(
                              (acc, curr) => acc + Number(curr["netEnergy"]),
                              0
                            ) + 10000000
                          }
                        />
                      </div>
                      <div>
                        <span>Net Energy (KWh)</span>
                        <span
                          style={{
                            position: "absolute",
                            left: ` ${
                              (netContractualEnergyShortfall?.reduce(
                                (acc, curr) => acc + Number(curr["netEnergy"]),
                                0
                              ) *
                                100) /
                                (netContractualEnergyShortfall?.reduce(
                                  (acc, curr) =>
                                    acc + Number(curr["netEnergy"]),
                                  0
                                ) +
                                  10000000) -
                              3
                            }%`,
                            bottom: "50%",
                          }}
                        >
                          <i class="fa-solid fa-caret-up"></i>
                        </span>

                        <span>
                          {convertToReadableValue(
                            netContractualEnergyShortfall
                              ?.reduce(
                                (acc, curr) => acc + Number(curr["netEnergy"]),
                                0
                              )
                              ?.toFixed(0)
                          )}
                        </span>
                      </div>
                    </div>
                    <div>
                      <div>
                        <progress
                          className="progress_2"
                          style={{
                            borderRadius: "14px",
                            border: progressValueAnimate.progress2
                              ? "3px solid black"
                              : "none",
                          }}
                          value={
                            netContractualEnergyShortfall?.reduce(
                              (acc, curr) =>
                                acc + Number(curr["contractualEnergy"]),
                              0
                            ) || 0
                          }
                          max={
                            netContractualEnergyShortfall?.reduce(
                              (acc, curr) =>
                                acc + Number(curr["contractualEnergy"]),
                              0
                            ) + 10000000
                          }
                        />
                      </div>

                      <div>
                        <span>Contractual Energy (KWh)</span>
                        <span
                          style={{
                            position: "absolute",
                            left: `${
                              ((netContractualEnergyShortfall?.reduce(
                                (acc, curr) =>
                                  acc + Number(curr["contractualEnergy"]),
                                0
                              ) || 0) *
                                100) /
                                (netContractualEnergyShortfall?.reduce(
                                  (acc, curr) =>
                                    acc + Number(curr["contractualEnergy"]),
                                  0
                                ) +
                                  10000000) -
                              3
                            }%`,
                            bottom: "50%",
                          }}
                        >
                          <i class="fa-solid fa-caret-up"></i>
                        </span>
                        <span>
                          {convertToReadableValue(
                            netContractualEnergyShortfall
                              ?.reduce(
                                (acc, curr) =>
                                  acc + Number(curr["contractualEnergy"]),
                                0
                              )
                              ?.toFixed(0)
                          )}
                        </span>
                      </div>
                    </div>
                    <div>
                      <div>
                        <progress
                          className="progress_3"
                          style={{
                            accentColor: "blue",
                            borderRadius: "14px",
                            border: progressValueAnimate.progress3
                              ? "3px solid red"
                              : "none",
                          }}
                          value={(
                            netContractualEnergyShortfall?.reduce(
                              (acc, curr) => acc + Number(curr["shortFall"]),
                              0
                            ) / netContractualEnergyShortfall?.length || 0
                          )?.toFixed(0)}
                          max="100"
                        />
                      </div>
                      <div>
                        <span>ShortFall/Excess(%)</span>
                        <span
                          style={{
                            position: "absolute",
                            left: `${
                              ((
                                netContractualEnergyShortfall?.reduce(
                                  (acc, curr) =>
                                    acc + Number(curr["shortFall"]),
                                  0
                                ) / netContractualEnergyShortfall?.length
                              )?.toFixed(0) *
                                100) /
                                100 -
                              3
                            }%`,
                            bottom: "50%",
                          }}
                        >
                          <i class="fa-solid fa-caret-up"></i>
                        </span>

                        <span>
                          {(
                           -netContractualEnergyShortfall?.reduce(
                              (acc, curr) => acc + Number(curr["shortFall"]),
                              0
                            ) / netContractualEnergyShortfall?.length
                          )?.toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <span>
                        {convertToReadableValue(
                          netContractualEnergyShortfall
                            ?.reduce(
                              (acc, curr) => acc + Number(curr["netEnergy"]),
                              0
                            )
                            ?.toFixed(0)
                        )}
                      </span>
                      <span>Net Energy Yearly (KWh) </span>
                      <div style={{ width: "80%", height: 30 }}>
                        <ResponsiveContainer>
                          <ComposedChart
                            margin={{
                              top: 0,
                              bottom: 0,
                              left: 0,
                              right: 0,
                            }}
                            data={
                              netContractualEnergyShortfall?.reduce(
                                (acc, curr, index) => {
                                  acc.push({
                                    name: curr["name"],
                                    netEnergy: curr["netEnergy"],
                                  });
                                  return acc;
                                },
                                []
                              ) || []
                            }
                          >
                            <XAxis dataKey="name" hide />
                            {/* <Tooltip content={<CustomizeTootip active={false} payload={[]} label={""} TooltipValues={["Net Energy"]} />} /> */}
                            <Bar
                              barSize={4}
                              radius={18}
                              dataKey="netEnergy"
                              fill="#8884d8"
                            />
                          </ComposedChart>
                        </ResponsiveContainer>
                      </div>
                      <span
                        onMouseEnter={() =>
                          setProgressValueAnimate({
                            ...progressValueAnimate,
                            progress1: true,
                          })
                        }
                        onMouseOut={() =>
                          setProgressValueAnimate({
                            ...progressValueAnimate,
                            progress1: false,
                          })
                        }
                        className="progress_button_1"
                      >
                        View
                      </span>
                    </div>
                    <div>
                      <span>
                        {convertToReadableValue(
                          netContractualEnergyShortfall
                            ?.reduce(
                              (acc, curr) =>
                                acc + Number(curr["contractualEnergy"]),
                              0
                            )
                            ?.toFixed(0)
                        ) || 0}
                      </span>
                      <span>Contractual Energy Yearly (KWh)</span>
                      <div style={{ width: "80%", height: 30 }}>
                        <ResponsiveContainer>
                          <ComposedChart
                            margin={{
                              top: 0,
                              bottom: 0,
                              left: 0,
                              right: 0,
                            }}
                            data={
                              netContractualEnergyShortfall?.reduce(
                                (acc, curr, index) => {
                                  acc.push({
                                    name: curr["name"],
                                    contractualEnergy:
                                      curr["contractualEnergy"],
                                  });
                                  return acc;
                                },
                                []
                              ) || []
                            }
                          >
                            <XAxis dataKey="name" hide />
                            {/* <Tooltip content={<CustomizeTootip active={false} payload={[]} label={""} TooltipValues={["Contractual Energy"]} />} /> */}
                            <Bar
                              barSize={4}
                              radius={18}
                              dataKey="contractualEnergy"
                              fill="#8884d8"
                            />
                          </ComposedChart>
                        </ResponsiveContainer>
                      </div>
                      <span
                        onMouseOver={() =>
                          setProgressValueAnimate({
                            ...progressValueAnimate,
                            progress2: true,
                          })
                        }
                        onMouseOut={() =>
                          setProgressValueAnimate({
                            ...progressValueAnimate,
                            progress2: false,
                          })
                        }
                        className="progress_button_2"
                      >
                        View
                      </span>
                    </div>
                    <div>
                      <span>
                        {(
                          -netContractualEnergyShortfall?.reduce(
                            (acc, curr) => acc + Number(curr["shortFall"]),
                            0
                          ) / netContractualEnergyShortfall?.length
                        )?.toFixed(2) || 0}
                      </span>
                      <span>Excess ShortFall Yearly (%)</span>
                      <div style={{ width: "80%", height: 30 }}>
                        <ResponsiveContainer>
                          <ComposedChart
                            margin={{
                              top: 0,
                              bottom: 0,
                              left: 0,
                              right: 0,
                            }}
                            data={
                              netContractualEnergyShortfall?.reduce(
                                (acc, curr, index) => {
                                  acc.push({
                                    name: curr["name"],
                                    shortFall: curr["shortFall"],
                                  });
                                  return acc;
                                },
                                []
                              ) || []
                            }
                          >
                            <XAxis dataKey="name" hide />
                            <YAxis
                              hide
                              // domain={[
                              //   Math.floor(netContractualEnergyShortfall?.reduce((acc, curr) => {
                              //     acc=Math.min(acc,curr["shortFall"])
                              //     return acc
                              //   }, Infinity)),
                              //   Math.ceil(netContractualEnergyShortfall?.reduce((acc, curr) => {
                              //     acc=Math.max(acc,curr["shortFall"])
                              //     return acc
                              //   }, -Infinity))
                              // ]}
                            />
                            {/* <Tooltip content={<CustomizeTootip active={false} payload={[]} label={""} TooltipValues={["Excess/Shortfall Percentage"]} />} /> */}
                            <Bar
                              barSize={4}
                              radius={18}
                              dataKey="shortFall"
                              fill="#8884d8"
                            />
                          </ComposedChart>
                        </ResponsiveContainer>
                      </div>
                      <span
                        className="progress_button_3"
                        onMouseOver={() =>
                          setProgressValueAnimate({
                            ...progressValueAnimate,
                            progress3: true,
                          })
                        }
                        onMouseOut={() =>
                          setProgressValueAnimate({
                            ...progressValueAnimate,
                            progress3: false,
                          })
                        }
                      >
                        View
                      </span>
                    </div>
                  </div>
                  </>
              )}
                </Grid>
                {loading2 ? (
                <Grid 
                item
                lg={3.1}
                md={5.9}
                sm={5.9}
                xs={12}
                style={{display:"flex",alignItems:"center",justifyContent:"center",background:"#fff"}}
                >
                  <GraphLoader />
                </Grid>
              ) : (
                <>
                <Grid
                  item
                  lg={3.1}
                  md={5.9}
                  sm={5.9}
                  xs={12}
                  className="gen-overview-2"
                >
                 
                  <ResponsiveContainer height="50%" width="100%">
                    <AreaChart
                      margin={{
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                      }}
                      data={
                        acLineLossActualPr || []
                      }

                      
                    >
                      <XAxis dataKey="name" hide />
                      <YAxis
                        type="number"
                        yAxisId="bar1"
                        hide
                        domain={[
                          Math.floor(
                            acLineLossActualPr?.reduce(
                              (acc, curr, index) =>
                                Math.min(curr["actualPr"], acc),
                              Infinity
                            )
                          ) - 1,
                          Math.ceil(
                            acLineLossActualPr?.reduce(
                              (acc, curr, index) =>
                                Math.max(curr["actualPr"], acc),
                              -Infinity
                            )
                          ) + 1,
                        ]}
                      />
                      <YAxis
                        type="number"
                        yAxisId="bar2"
                        hide
                        domain={[
                          Math.floor(
                            acLineLossActualPr?.reduce(
                              (acc, curr, index) =>
                                Math.min(curr["plantAvailability"], acc),
                              Infinity
                            )
                          ) - 1,
                          Math.ceil(
                            acLineLossActualPr?.reduce(
                              (acc, curr, index) =>
                                Math.max(curr["plantAvailability"], acc),
                              -Infinity
                            )
                          ) + 1,
                        ]}
                      />
                      <Area
                        dataKey="actualPr"
                        yAxisId="bar1"
                        stroke="rgb(11, 165, 119)"
                        strokeWidth={0.2}
                        fill="rgb(20, 213, 149)"
                      />

<Area
                        dataKey="cuf"
                        yAxisId="bar1"
                        stroke="rgb(11, 110, 100)"
                        strokeWidth={0.2}
                        fill="rgb(10, 190, 129)"
                      />
                      <Area
                        dataKey="plantAvailability"
                        yAxisId="bar2"
                        stroke="rgb(11, 165, 119)"
                        strokeWidth={0.2}
                        fill="rgb(91, 248, 201)"
                      />
                      <Tooltip
                        content={
                          <CustomizeTootip
                            active={false}
                            payload={[]}
                            label={""}
                            TooltipValues={["Actual PR", "Plant Availability","CUF"]}
                          />
                        }
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                  <div>
                    <div>
                      <span>
                        {(
                          acLineLossActualPr?.reduce(
                            (acc, curr) => acc + Number(curr["actualPr"]),
                            0
                          ) / acLineLossActualPr?.length
                        ).toFixed(2)}
                      </span>
                      <span>Actual PR (%)</span>
                    </div>
                    <span
                      style={{
                        width: "2px",
                        background: "rgb(166, 176, 173)",
                        height: "90%",
                      }}
                    ></span>
                    <div>
                      <span>
                        {(
                          acLineLossActualPr?.reduce(
                            (acc, curr) =>
                              acc + Number(curr["plantAvailability"]),
                            0
                          ) / acLineLossActualPr?.length
                        ).toFixed(2)}
                      </span>
                      <span>Plant Availability (%) </span>
                    </div>
                    <span
                      style={{
                        width: "2px",
                        background: "rgb(166, 176, 173)",
                        height: "90%",
                      }}
                    ></span>
                    <div>
                      <span>
                        {(
                          acLineLossActualPr?.reduce(
                            (acc, curr) =>
                              acc + Number(curr["cuf"]),
                            0
                          ) / acLineLossActualPr?.length
                        ).toFixed(2)}
                      </span>
                      <span>CUF (%) </span>
                    </div>
                  </div>
                </Grid></>)}
                {loading2 ? (
                <Grid 
                item
                lg={3.1}
                md={5.9}
                sm={5.9}
                xs={12}
                style={{display:"flex",alignItems:"center",justifyContent:"center",background:"#fff"}}
                >
                
                  <GraphLoader />
                </Grid>
              ) : (
                <>
                <Grid
                  item
                  lg={3.1}
                  md={5.9}
                  sm={5.9}
                  xs={12}
                  className="gen-overview-3"
                >
                  
                  <span>AC Line Loss (%)</span>
                  <ResponsiveContainer height="90%" width="90%">
                    <ComposedChart
                      title="Ac Line Loss Percentage Monthly"
                      margin={{
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                      }}
                      data={
                        acLineLossActualPr?.reduce((acc, curr, index) => {
                          acc.push({
                            name: curr["name"],
                            ac_loss: curr["acLineLoss"],
                          });
                          return acc;
                        }, []) || []
                      }
                    >
                      <XAxis
                        axisLine={false}
                        dataKey="name"
                        // interval={0}
                        fontSize={"0.6rem"}
                        // tickMargin={8}
                        fontWeight={600}
                        tickLine={false}
                        angle={false}
                        display={false}
                      />
                      <YAxis
                        hide
                        domain={[
                          Math.floor(
                            acLineLossActualPr?.reduce(
                              (acc, curr, index) =>
                                Math.min(curr["acLineLoss"], acc),
                              Infinity
                            )
                          ),
                          Math.ceil(
                            acLineLossActualPr?.reduce(
                              (acc, curr, index) =>
                                Math.max(curr["acLineLoss"], acc),
                              -Infinity
                            )
                          ) + 1,
                        ]}
                      />
                      <Tooltip
                        cursor={{ fill: "none" }}
                        content={
                          <CustomizeTootip
                            active={false}
                            payload={[]}
                            label={""}
                            TooltipValues={["Ac Loss"]}
                          />
                        }
                      />
                      <Bar
                        barSize={12}
                        radius={18}
                        dataKey="ac_loss"
                        fill="rgb(61, 74, 138)"
                      ></Bar>
                    </ComposedChart>
                  </ResponsiveContainer>
                  <span>2024</span>
                 
                </Grid> </>)}
              </Grid>
              {/* ###   GenOverview Container 1 end ### */}

              {/* ###   GenOverview Container 2 start ### */}

              <Grid
                item
                xl={11.8}
                lg={11.8}
                md={11.8}
                sm={11.8}
                xs={11.8}
                // borderRadius={"14px"}
                sx={{
                  background:
                    "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
                  margin: "0vmax auto 1vmax",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "1vmax",
                }}
              >
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{
                    position: "relative",
                    backgroundColor: "#fff",
                    padding: "1vmax",
                    overflow: "auto",
                  }}
                >
                  <NetContractual
                    netContractualEnergyShortfall={
                      netContractualEnergyShortfall
                    }
                    loading={loading}
                  />
                </Grid>
              </Grid>
              {/* ###   GenOverview Container 2 end ### */}

              {/* ###   GenOverview Container 3 start ### */}

              <Grid
                item
                xl={11.8}
                lg={11.8}
                md={11.8}
                sm={11.8}
                xs={11.8}
                // borderRadius={"14px"}
                sx={{
                  background:
                    "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
                  margin: "0vmax auto 1vmax",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "1vmax",
                }}
              >
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{
                    position: "relative",
                    backgroundColor: "#fff",
                    padding: "1vmax",
                    overflow: "auto",
                  }}
                >
                  <ExcessShortFall
                    netContractualEnergyShortfall={
                      netContractualEnergyShortfall
                    }
                    loading={loading}
                  />
                </Grid>
              </Grid>
              {/* ###   GenOverview Container 3 end ### */}
              {/* ###   GenOverview Container 4 start ### */}

              <Grid
                item
                xl={11.8}
                lg={11.8}
                md={11.8}
                sm={11.8}
                xs={11.8}
                // borderRadius={"14px"}
                style={{
                  background:
                    "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
                  margin: "0vmax auto 1vmax",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "1vmax",
                }}
              >
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{
                    position: "relative",
                    backgroundColor: "#fff",
                    padding: "1vmax",
                    overflow: "auto",
                  }}
                  ref={speedometerRef3}
                >
                  <ActualPrACLineLoss
                    acLineLossActualPr={acLineLossActualPr}
                    loading2={loading2}
                  />
                </Grid>
              </Grid>
              {/* ###   GenOverview Container 4 end ### */}
              {/* ###   GenOverview Container 5 start ### */}

              <Grid
                item
                xl={11.8}
                lg={11.8}
                md={11.8}
                sm={11.8}
                xs={11.8}
                // borderRadius={"14px"}
                style={{
                  background:
                    "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
                  margin: "0 auto 1vmax",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "1vmax",
                }}
              >
                <Grid
                  container
                  alignItems={"center"}
                  justifyContent={"center"}
                  gap={"1vmax"}
                >
                  <Grid
                    item
                    xl={5.9}
                    lg={5.9}
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{
                      position: "relative",
                      backgroundColor: "#fff",
                      padding: "1vmax",
                      overflow: "auto",
                    }}
                  >
                    <NetContractualShortFall
                      netContractualEnergyShortfall={
                        netContractualEnergyShortfall
                      }
                      loading={loading}
                    />
                  </Grid>
                  <Grid
                    item
                    xl={5.9}
                    lg={5.9}
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{
                      position: "relative",
                      backgroundColor: "#fff",
                      padding: "1vmax",
                      overflow: "auto",
                    }}
                  >
                    <AcutalPR
                      acLineLossActualPr={acLineLossActualPr}
                      loading={loading2}
                    />
                  </Grid>
                </Grid>

                {/* ###   GenOverview Container 5 end ### */}
              </Grid>
            </Grid>
          </div>
          {/* )} */}
        </>
      }
    </>
  );
};

export default GenOverView;

