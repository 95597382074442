import React, { useEffect, useMemo, useState } from "react";
import CountryGuard from "./guard/CountryGuard";
import CountryDefault from "./components/CountryDefault";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PageURL from "../../constants/PageURL";
import DiagonisticGuard from "./guard/DiagonisticGuard";
import Diagonistic from "./components/diagonisticModel/Diagonistic";
import CountryHeader2 from "../countries/components/Headers/CountryHeader2";
import Footer from "../../components/Footer";
import ProjectDetailBody from "./components/ProjectDetails/ProjectDetailBody";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { DesktopDatePicker, MobileDatePicker } from "@mui/x-date-pickers";

import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import ProjOverView from "./components/projectOverView/ProjOverView";
import CountryHeader3 from "./components/Headers/CountryHeader3";
import DateRangeComp from "../../components/DateRangeComp";
import { useSelector } from "react-redux";
import FaultTree from "./components/prescriptiveModel/FaultTree";
import LossflowTable from "./components/diagonisticModel/LossflowTable";
import PresCripModel from "./components/prescriptiveModel/PresCripModel";
import LossFlow from "./components/diagonisticModel/LossFlow";

const CountryModule = () => {
  const { projectDetail } = useSelector((state) => state.user);

  const [openSidebar, setOpenSidebar] = useState(false);
  const [databaseStr, setDatabaseStr] = useState(null);
  const [selectedValue, setSelectedValue] = useState({
    country: "",
    partnerName: "",
    projectName: "",
    projectDetail: "",
  });
  const [dateTypeValue, setDateTypeValue] = useState({
    yearType: "",
    startYear: "",
    endYear: "",
    startMonth: "",
    endMonth: "",
    date: "",
  });
  const [scbsmbDetail, setscbsmbDetail] = useState({
    block: null,
    inverter: null,
    inverterType: null,
    scbsmb: null,
    labelData: null,
  });
  const [selectedLebel, sestSelectedLebel] = useState({
    year: false,
    month: false,
    day: false,
    yearData: null,
    monthData: null,
    startData: null,
    endData: null,
    yearDataValue: null,
    monthDataValue: null,
    startDataValue: null,
    endDataValue: null,
  });

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const serachParams = new URLSearchParams(location.search);

  const [locationPath, setLocationPath] = useState(location.pathname);
  useMemo(() => {
    setSelectedValue({
      ...selectedValue,
      country: serachParams.get("country") || "",
      partnerName: serachParams.get("partner-name") || "",
      projectName: serachParams.get("project-name") || "",
    });
  }, []);
  useEffect(() => {
    if (location.pathname !== locationPath) {
      setscbsmbDetail({
        ...scbsmbDetail,
        block: null,
        inverter: null,
        inverterType: null,
        scbsmb: null,
        labelData: null,
      });
      sestSelectedLebel({
        ...selectedLebel,
        year: false,
        month: false,
        day: false,
        labelValue: null,
        yearData: null,
        monthData: null,
        startData: null,
        endData: null,
        yearDataValue: null,
        monthDataValue: null,
        startDataValue: null,
        endDataValue: null,
      });
      setLocationPath(location.pathname);
    }
    if (projectDetail && selectedValue.projectDetail) {
      setscbsmbDetail({
        ...scbsmbDetail,
        labelData: ["Year", "Month", "Day"],
      });
    }
    if (
      selectedValue.country &&
      selectedValue.partnerName &&
      selectedValue.projectName
    ) {
      setDatabaseStr(
        (
          selectedValue.country +
          selectedValue.partnerName +
          selectedValue.projectName
        )
          ?.split(" ")
          .join("")
      );
    }

    if (selectedValue.country) {
      serachParams.set("country", selectedValue.country);
    } else {
      ["country", "partner-name", "project-name", "project-detail"].forEach(
        (e) => serachParams.delete(e)
      );
    }
    if (selectedValue.partnerName) {
      serachParams.set("partner-name", selectedValue.partnerName);
    } else
      ["partner-name", "project-name", "project-detail"].forEach((e) =>
        serachParams.delete(e)
      );
    if (selectedValue.projectName) {
      serachParams.set("project-name", selectedValue.projectName);
    } else
      ["project-name", "project-detail"].forEach((e) => serachParams.delete(e));
    if (selectedValue.projectDetail) {
      serachParams.set("project-detail", selectedValue.projectDetail);
    } else ["project-detail"].forEach((e) => serachParams.delete(e));

    navigate(`${location.pathname}?${serachParams.toString()}`);
  }, [navigate, selectedValue, location.pathname, projectDetail]);

  switch (location.pathname) {
    case PageURL.PROJECT_DETAILS:

    case PageURL.INDIA_PRES_MODEL_Loss_Flow:
    case PageURL.INDIA_PRES_MODEL_Fault_Tree:
    // return (
    //   <>
    //     <CountryHeader3
    //     databaseStr={databaseStr}
    //       scbsmbDetail={scbsmbDetail}
    //       setscbsmbDetail={setscbsmbDetail}
    //       openSidebar={openSidebar}
    //       setOpenSidebar={setOpenSidebar}
    //       selectedValue={selectedValue}
    //       setSelectedValue={setSelectedValue}
    //       selectedLebel={selectedLebel}
    //     />
    //     <div
    //       style={{ marginTop: openSidebar ? "60px" : "0px" }}
    //       className="country-module-container"
    //     >
    //       {location.pathname === PageURL.PROJECT_DETAILS && (
    //         <ProjectDetailBody />
    //       )}

    //       {location.pathname === PageURL.INDIA_PROJ_OVERVIEW && (
    //         <ProjOverView databaseStr={databaseStr} />
    //       )}
    //       {location.pathname === PageURL.INDIA_PRES_MODEL_Fault_Tree && (
    //         <FaultTree />
    //       )}
    //       {location.pathname === PageURL.INDIA_PRES_MODEL_Loss_Flow && (
    //         <PresCripModel />
    //       )}
    //       {location.pathname === PageURL.INDIA_LOSS_FLOW && <LossFlow />}
    //     </div>
    //     <Footer />
    //   </>
    // );
    case PageURL.INDIA_INVERTER_EFFICIENCY:
    case PageURL.INDIA_PROJ_OVERVIEW:
    case PageURL.INDIA_GEN_SUMMARY:

    case PageURL.INDIA_DIAGONISTIC_DETAILED:
    case PageURL.INDIA_LOSS_FLOW:

    case PageURL.INDIA_INVERTER1_HEATMAP_DIAGRAM:
    case PageURL.INDIA_INVERTER2_HEATMAP_DIAGRAM:
    case PageURL.INDIA_INVERTER3_HEATMAP_DIAGRAM:
    case PageURL.INDIA_INVERTER4_HEATMAP_DIAGRAM:
    case PageURL.SCBSMB_LOSS:
      return (
        <>
          <CountryHeader3
            databaseStr={databaseStr}
            selectedLebel={selectedLebel}
            sestSelectedLebel={sestSelectedLebel}
            scbsmbDetail={scbsmbDetail}
            setscbsmbDetail={setscbsmbDetail}
            openSidebar={openSidebar}
            setOpenSidebar={setOpenSidebar}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
          />
          <div
            style={{ marginTop: openSidebar ? "60px" : "0px" }}
            className="country-module-container"
          >
            {location.pathname !== PageURL.PROJECT_DETAILS &&
              location.pathname !== PageURL.INDIA_PROJ_OVERVIEW &&
              location.pathname !== PageURL.INDIA_PRES_MODEL_Fault_Tree &&
              location.pathname !== PageURL.INDIA_PRES_MODEL_Loss_Flow &&
              location.pathname !== PageURL.INDIA_LOSS_FLOW && (
                <Grid container xl={12} textAlign={"center"}>
                  <Grid
                    sx={{ boxShadow: 2 }}
                    item
                    xl={11.8}
                    lg={11.8}
                    md={11.8}
                    sm={11.8}
                    xs={11.8}
                    style={{
                      background:
                        "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
                      boxSizing: "border-box",
                      borderWidth: "3px",
                      // borderRadius: "8px",
                      margin: "1vmax auto",
                      padding: "1vmax",
                    }}
                  >
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{
                        position: "relative",
                        backgroundColor: "#fff",
                        padding: "0.5vmax 0",
                        display: "flex",
                        alignItems: "center",
                        height: "60px",
                      }}
                    >
                      <div
                        style={{
                          padding: "0 1vmax",
                          display: "flex",
                          alignItems: "center",
                          whiteSpace: "nowrap",
                        }}
                        className="selected-detail-container"
                      >
                        {selectedValue.country && (
                          <span>{selectedValue.country}</span>
                        )}
                        {selectedValue.partnerName && (
                          <span> {`${selectedValue.partnerName}`} </span>
                        )}
                        {selectedValue.projectName && (
                          <span> {" " + selectedValue.projectName}</span>
                        )}
                        {selectedValue.projectDetail && (
                          <span> {" " + selectedValue.projectDetail}</span>
                        )}
                        {/* {scbsmbDetail.block && (
                      <span> {" " + scbsmbDetail.block}</span>
                    )}
                    {scbsmbDetail.inverter && (
                      <span> {" " + scbsmbDetail.inverter}</span>
                    )}
                    
                    {scbsmbDetail.scbsmb && (
                      <span> {" " + scbsmbDetail.scbsmb}</span>
                    )} */}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "end",
                          gap: "1vmin",
                          padding: "0 1vmax",
                          position: "relative",
                        }}
                      >
                        <DateRangeComp
                          selectedLebel={selectedLebel}
                          sestSelectedLebel={sestSelectedLebel}
                          scbsmbDetail={scbsmbDetail}
                          labelData={scbsmbDetail.labelData}
                          selectedValue={selectedValue}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              )}

            <DiagonisticGuard>
              <Diagonistic
                databaseStr={databaseStr}
                selectedLebel={selectedLebel}
                sestSelectedLebel={setSelectedValue}
                setscbsmbDetail={setscbsmbDetail}
                scbsmbDetail={scbsmbDetail}
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
              />
            </DiagonisticGuard>
          </div>
          <Footer />
        </>
      );
    default:
      return null;
  }
};

export default CountryModule;
