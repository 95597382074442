import React, { useEffect, useRef, useState } from "react";
import "./GenOverView2.css";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import SpeedChart from "../../../../components/SpeedChart";
import { useDispatch, useSelector } from "react-redux";
import { normalizedEnergyDetails } from "../../../../actions/inverterActions";
import SpinLoader from "../../../../components/SpinLoader";
import SelectOptions from "./SelectOptions";
import Printer from "../../../../components/Printer";
import { CSVLink } from "react-csv";
import { saveAs } from "file-saver";
import {
  Area,
  AreaChart,
  Bar,
  CartesianGrid,
  ComposedChart,
  LabelList,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import CustomizeTootip from "../../../../components/CustomizeTootip";
import CustomizeLegend from "../../../../components/CustomizeLegend";
import { colors1, colors3 } from "../../../../colors/color";
import { exportComponentAsJPEG } from "react-component-export-image";
import { convertToReadableValue, saveToSvg } from "../../../../constants/Utils";
import Printer2 from "../../../../components/Printer2";
// import { DemoContainer, DemoItem } from "@mui/x-date-pickers";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import dayjs from "dayjs";

// const date1 = ["2018", "2019", "2020", "2021", "2022", "2023", "2024"];
// const date2 = [
//   "2018-2019",
//   "2019-2020",
//   "2020-2021",
//   "2021-2022",
//   "2022-2023",
//   "2023-2024",
// ];

const GenOverView2 = () => {
  const { loading, energy } = useSelector((state) => state.energy);
  //
  const [dateType, setDateType] = useState(0);
  const [dateTypeValue, setDateTypeValue] = useState({
    type: "",
    date: "",
  });
  const [openDateModal, setOpenModal] = useState({
    type: false,
    date: false,
  });

  /* ###   Initialize month for Gaugemeter Default value   ### */

  const [initialMonth, setInitialMonth] = useState({
    month1: "January",
    month2: "January",
    month3: "January",
  });

  /* ###   Initialize State to animate progress bar   ### */

  const [progressValueAnimate, setProgressValueAnimate] = useState({
    progress1: false,
    progress2: false,
    progress3: false,
  });

  /* ###   Initialize State to animate Graph Bars and Lines   ### */

  const [showPRData, setShowPRData] = useState({ showPR: true, all: true });
  const [showEnergyData, setShowEnergyData] = useState({
    netEnergy: true,
    contractualEnergy: true,
    shortFall: true,
    all: true,
  });

  /* ###   Reference to download graph data in CSV format   ### */

  const downloadRef1 = useRef(null);
  const downloadRef2 = useRef(null);
  const downloadRef3 = useRef(null);
  const downloadRef4 = useRef(null);
  const downloadRef5 = useRef(null);

  /* ###   Reference for Graph container TO Download In Jpg and Svg format   ### */

  const speedometerRef1 = useRef(null);
  const speedometerRef2 = useRef(null);
  const speedometerRef3 = useRef(null);
  const graphRef1 = useRef(null);
  const graphRef2 = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(normalizedEnergyDetails());
  }, [dispatch]);

  return (
    <>
      {loading ? (
        <SpinLoader />
      ) : (
        <>
          {energy?.data1 && energy?.data2 && (
            <div>
              <Grid
                container
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                textAlign={"center"}
              >
                {/* ###   GenOverview Container 1 Start   ### */}
                <Grid
                  sx={{ boxShadow: 2 }}
                  container
                  xl={11.8}
                  lg={11.8}
                  md={11.8}
                  sm={11.8}
                  xs={11.8}
                  columnGap={"1vmax"}
                  rowGap={"1vmax"}
                  style={{
                    boxSizing: "border-box",
                    borderWidth: "3px",
                    // borderRadius: "8px",
                    margin: "1vmax auto",
                    padding: "1vmax",
                    background:
                      "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
                  }}
                  justifyContent={"space-between"}
                  className="generation-overview-container"
                >
                  <Grid
                    lg={5.5}
                    md={12}
                    sm={12}
                    xs={12}
                    className="gen-overview-1"
                  >
                    <div>
                      <div>
                        <div>
                          <progress
                            className="progress_1"
                            style={{
                              accentColor: "green",
                              borderRadius: "14px",
                              border: progressValueAnimate.progress1
                                ? "3px solid red"
                                : "none",
                            }}
                            value={
                              energy?.data1?.find((e) => e.month === "Yearly")
                                ?.net_energy || 0
                            }
                            max="20000000"
                          />
                        </div>
                        <div>
                          <span>Net Energy (KWh)</span>
                          <span
                            style={{
                              position: "absolute",
                              left: ` ${
                                ((energy?.data1?.find(
                                  (e) => e.month === "Yearly"
                                )?.net_energy || 0) *
                                  100) /
                                  20000000 -
                                3
                              }%`,
                              bottom: "50%",
                            }}
                          >
                            <i class="fa-solid fa-caret-up"></i>
                          </span>

                          <span>
                            {convertToReadableValue(
                              energy?.data1?.find((e) => e.month === "Yearly")
                                ?.net_energy
                            )}
                          </span>
                        </div>
                      </div>
                      <div>
                        <div>
                          <progress
                            className="progress_2"
                            style={{
                              borderRadius: "14px",
                              border: progressValueAnimate.progress2
                                ? "3px solid black"
                                : "none",
                            }}
                            value={
                              energy?.data1?.find((e) => e.month === "Yearly")
                                ?.contractual_energy || 0
                            }
                            max="20000000"
                          />
                        </div>

                        <div>
                          <span>Contractual Energy (KWh)</span>
                          <span
                            style={{
                              position: "absolute",
                              left: `${
                                ((energy?.data1?.find(
                                  (e) => e.month === "Yearly"
                                )?.contractual_energy || 0) *
                                  100) /
                                  20000000 -
                                3
                              }%`,
                              bottom: "50%",
                            }}
                          >
                            <i class="fa-solid fa-caret-up"></i>
                          </span>
                          <span>
                            {convertToReadableValue(
                              energy?.data1?.find((e) => e.month === "Yearly")
                                ?.contractual_energy
                            )}
                          </span>
                        </div>
                      </div>
                      <div>
                        <div>
                          <progress
                            className="progress_3"
                            style={{
                              accentColor: "blue",
                              borderRadius: "14px",
                              border: progressValueAnimate.progress3
                                ? "3px solid red"
                                : "none",
                            }}
                            value={
                              -energy?.data1?.find((e) => e.month === "Yearly")
                                ?.ExcessORShortfall_Percentage
                            }
                            max="100"
                          />
                        </div>
                        <div>
                          <span>ShortFall/Excess(%)</span>
                          <span
                            style={{
                              position: "absolute",
                              left: `${
                                (-(
                                  energy?.data1?.find(
                                    (e) => e.month === "Yearly"
                                  )?.ExcessORShortfall_Percentage || 0
                                ) *
                                  100) /
                                  100 -
                                3
                              }%`,
                              bottom: "50%",
                            }}
                          >
                            <i class="fa-solid fa-caret-up"></i>
                          </span>

                          <span>
                            {
                              energy?.data1?.find((e) => e.month === "Yearly")
                                ?.ExcessORShortfall_Percentage
                            }
                          </span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <span>
                          {convertToReadableValue(
                            energy?.data1?.find((e) => e.month === "Yearly")
                              ?.net_energy
                          ) || 0}
                        </span>
                        <span>Net Energy Yearly (KWh) </span>
                        <div style={{ width: "80%", height: 30 }}>
                          <ResponsiveContainer>
                            <ComposedChart
                              margin={{
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                              }}
                              data={
                                energy?.data1?.reduce((acc, curr, index) => {
                                  if (index !== 0) {
                                    acc.push({
                                      name: curr.month,
                                      net_energy: curr.net_energy,
                                    });
                                    return acc;
                                  } else return acc;
                                }, []) || []
                              }
                            >
                              <XAxis dataKey="name" hide />
                              {/* <Tooltip content={<CustomizeTootip active={false} payload={[]} label={""} TooltipValues={["Net Energy"]} />} /> */}
                              <Bar
                                barSize={4}
                                radius={18}
                                dataKey="net_energy"
                                fill="#8884d8"
                              />
                            </ComposedChart>
                          </ResponsiveContainer>
                        </div>
                        <span
                          onMouseEnter={() =>
                            setProgressValueAnimate({
                              ...progressValueAnimate,
                              progress1: true,
                            })
                          }
                          onMouseOut={() =>
                            setProgressValueAnimate({
                              ...progressValueAnimate,
                              progress1: false,
                            })
                          }
                          className="progress_button_1"
                        >
                          View
                        </span>
                      </div>
                      <div>
                        <span>
                          {convertToReadableValue(
                            energy?.data1?.find((e) => e.month === "Yearly")
                              ?.contractual_energy
                          ) || 0}
                        </span>
                        <span>Contractual Energy Yearly (KWh)</span>
                        <div style={{ width: "80%", height: 30 }}>
                          <ResponsiveContainer>
                            <ComposedChart
                              margin={{
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                              }}
                              data={
                                energy?.data1?.reduce((acc, curr, index) => {
                                  if (index !== 0) {
                                    acc.push({
                                      name: curr.month,
                                      contractual_energy:
                                        curr.contractual_energy,
                                    });
                                    return acc;
                                  } else return acc;
                                }, []) || []
                              }
                            >
                              <XAxis dataKey="name" hide />
                              {/* <Tooltip content={<CustomizeTootip active={false} payload={[]} label={""} TooltipValues={["Contractual Energy"]} />} /> */}
                              <Bar
                                barSize={4}
                                radius={18}
                                dataKey="contractual_energy"
                                fill="#8884d8"
                              />
                            </ComposedChart>
                          </ResponsiveContainer>
                        </div>
                        <span
                          onMouseOver={() =>
                            setProgressValueAnimate({
                              ...progressValueAnimate,
                              progress2: true,
                            })
                          }
                          onMouseOut={() =>
                            setProgressValueAnimate({
                              ...progressValueAnimate,
                              progress2: false,
                            })
                          }
                          className="progress_button_2"
                        >
                          View
                        </span>
                      </div>
                      <div>
                        <span>
                          {energy?.data1?.find((e) => e.month === "Yearly")
                            ?.ExcessORShortfall_Percentage || 0}
                        </span>
                        <span>Excess ShortFall Yearly (%)</span>
                        <div style={{ width: "80%", height: 30 }}>
                          <ResponsiveContainer>
                            <ComposedChart
                              margin={{
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                              }}
                              data={
                                energy?.data1?.reduce((acc, curr, index) => {
                                  if (index !== 0) {
                                    acc.push({
                                      name: curr.month,
                                      excess_shortfall_percentage:
                                        curr.ExcessORShortfall_Percentage,
                                    });
                                    return acc;
                                  } else return acc;
                                }, []) || []
                              }
                            >
                              <XAxis dataKey="name" hide />
                              <YAxis
                                dataKey={() =>
                                  energy?.data1?.reduce((acc, curr) => {
                                    return curr.ExcessORShortfall_Percentage >=
                                      acc
                                      ? curr.ExcessORShortfall_Percentage
                                      : acc;
                                  }, -Infinity)
                                }
                                hide
                                domain={[
                                  energy?.data1?.reduce((acc, curr) => {
                                    return curr.ExcessORShortfall_Percentage <=
                                      acc
                                      ? curr.ExcessORShortfall_Percentage
                                      : acc;
                                  }, Infinity),
                                  `dataMax`,
                                ]}
                              />
                              {/* <Tooltip content={<CustomizeTootip active={false} payload={[]} label={""} TooltipValues={["Excess/Shortfall Percentage"]} />} /> */}
                              <Bar
                                barSize={4}
                                radius={18}
                                dataKey="excess_shortfall_percentage"
                                fill="#8884d8"
                              />
                            </ComposedChart>
                          </ResponsiveContainer>
                        </div>
                        <span
                          className="progress_button_3"
                          onMouseOver={() =>
                            setProgressValueAnimate({
                              ...progressValueAnimate,
                              progress3: true,
                            })
                          }
                          onMouseOut={() =>
                            setProgressValueAnimate({
                              ...progressValueAnimate,
                              progress3: false,
                            })
                          }
                        >
                          View
                        </span>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    lg={3.1}
                    md={5.9}
                    sm={5.9}
                    xs={12}
                    className="gen-overview-2"
                  >
                    <ResponsiveContainer height="50%" width="100%">
                      <AreaChart
                        margin={{
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0,
                        }}
                        data={
                          energy?.data1?.reduce((acc, curr, index) => {
                            if (index !== 0) {
                              acc.push({
                                name: curr.month,
                                actual_pr: curr.Actual_pr,
                                plant_availability:
                                  energy?.data4[index - 1].plant_availability,
                              });
                              return acc;
                            } else return acc;
                          }, []) || []
                        }

                        // margin={{
                        //   top: 20, right: 20, bottom: 20, left: 20,
                        // }}
                      >
                        <XAxis dataKey="name" hide />
                        <YAxis
                          type="number"
                          yAxisId="bar1"
                          hide
                          domain={[70, 100]}
                        />
                        <YAxis
                          type="number"
                          yAxisId="bar2"
                          hide
                          domain={[98, 99.5]}
                        />
                        <Area
                          dataKey="actual_pr"
                          yAxisId="bar1"
                          stroke="rgb(11, 165, 119)"
                          strokeWidth={0.2}
                          fill="rgb(20, 213, 149)"
                        />
                        <Area
                          dataKey="plant_availability"
                          yAxisId="bar2"
                          stroke="rgb(11, 165, 119)"
                          strokeWidth={0.2}
                          fill="rgb(91, 248, 201)"
                        />
                        <Tooltip
                          content={
                            <CustomizeTootip
                              active={false}
                              payload={[]}
                              label={""}
                              TooltipValues={[
                                "Actual PR",
                                "Plant Availability",
                              ]}
                            />
                          }
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                    <div>
                      <div>
                        <span>
                          {
                            energy?.data1?.find((e) => e.month === "Yearly")
                              ?.Actual_pr
                          }
                        </span>
                        <span>Actual PR (%)</span>
                      </div>
                      <span
                        style={{
                          width: "2px",
                          background: "rgb(166, 176, 173)",
                          height: "90%",
                        }}
                      ></span>
                      <div>
                        <span>
                          {parseFloat(
                            energy?.data4?.reduce(
                              (acc, curr) => acc + curr.plant_availability,
                              0
                            ) / 12
                          ).toFixed(2)}
                        </span>
                        <span>Plant Availability (%) </span>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    lg={3.1}
                    md={5.9}
                    sm={5.9}
                    xs={12}
                    className="gen-overview-3"
                  >
                    <span>AC Line Loss (%)</span>
                    <ResponsiveContainer height="100%" width="90%">
                      <ComposedChart
                        title="Ac Line Loss Percentage Monthly"
                        margin={{
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0,
                        }}
                        data={
                          energy?.data1?.reduce((acc, curr, index) => {
                            if (index === 0) return acc;
                            else {
                              acc.push({
                                name: curr?.month.substr(0, 3),
                                ac_loss: curr?.AC_Loss,
                              });
                              return acc;
                            }
                          }, []) || []
                        }
                      >
                        <XAxis
                          axisLine={false}
                          dataKey="name"
                          interval={0}
                          fontSize={"0.6rem"}
                          tickMargin={8}
                          fontWeight={600}
                          tickLine={false}
                          angle={false}
                          display={false}
                        />
                        <YAxis
                          hide
                          domain={[
                            energy?.data1?.reduce(
                              (acc, curr, index) => Math.min(curr.AC_Loss, acc),
                              Infinity
                            ) - 0.1,
                            energy?.data1?.reduce(
                              (acc, curr, index) => Math.max(curr.AC_Loss, acc),
                              -Infinity
                            ) + 0.1,
                          ]}
                        />
                        <Tooltip
                          cursor={{ fill: "none" }}
                          content={
                            <CustomizeTootip
                              active={false}
                              payload={[]}
                              label={""}
                              TooltipValues={["Ac Loss"]}
                            />
                          }
                        />
                        <Bar
                          barSize={12}
                          radius={18}
                          dataKey="ac_loss"
                          fill="rgb(61, 74, 138)"
                        ></Bar>
                      </ComposedChart>
                    </ResponsiveContainer>
                    <span>2021-2022</span>
                  </Grid>
                </Grid>
                {/* ###   GenOverview Container 1 end ### */}

                {/* ###   GenOverview Container 2 start ### */}

                <Grid
                  item
                  xl={11.8}
                  lg={11.8}
                  md={11.8}
                  sm={11.8}
                  xs={11.8}
                  // borderRadius={"14px"}
                  sx={{
                    background:
                      "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
                    margin: "0vmax auto 1vmax",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "1vmax",
                  }}
                >
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{
                      position: "relative",
                      backgroundColor: "#fff",
                      padding: "1vmax",
                      overflow: "auto",
                    }}
                    ref={speedometerRef1}
                  >
                    <CSVLink
                      data={energy?.data1?.reduce((acc, curr) => {
                        acc.push({
                          Month: curr.month,
                          NetEnergy: curr.net_energy,
                          ContractualEnergy: curr.contractual_energy,
                        });
                        return acc;
                      }, [])}
                      filename="data.csv"
                      className="hidden"
                      ref={downloadRef1}
                      target="_blank"
                    />

                    <div
                      style={{
                        padding: "0 1.5rem",
                        position: "sticky",
                        left: "0",
                        right: "0",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        zIndex: "1",
                      }}
                    >
                      <select
                        name="level"
                        // id="level"
                        style={{
                          padding: "1vmin 1.5vmin",
                          textAlign: "center",
                          fontWeight: "bolder",
                          borderStyle: "solid",
                          borderColor: "#ED7D31",
                          borderWidth: "3px",
                          fontSize: "1rem",
                          backgroundColor: "#edeaea",
                        }}
                        onChange={(e) => {
                          setInitialMonth({
                            ...initialMonth,
                            month1: e.target.value,
                          });
                        }}
                      >
                        {/* ###  Monthly Value component for Gaugemeter ### */}

                        <SelectOptions />
                      </select>
                      <Printer2
                        clickhandler={() => downloadRef1.current.link.click()}
                        jpgDownload={() => {
                          document.getElementById(
                            "hide_content"
                          ).style.display = "none";
                          exportComponentAsJPEG(
                            speedometerRef1,
                            (document.getElementById(
                              "greenenco"
                            ).style.display = "block")
                          );
                          document.getElementById("greenenco").style.display =
                            "none";
                        }}
                        svgDownload={async () => {
                         
                          document.getElementById(
                            "hide_content"
                          ).style.display = "none";
                          const svgData = await saveToSvg(
                            speedometerRef1.current,
                            (document.getElementById(
                              "greenenco"
                            ).style.display = "block")
                          );
                          document.getElementById("greenenco").style.display =
                            "none";
                          saveAs(svgData, "gaugemeter.svg");
                        }}
                      />
                    </div>
                    <div className="speed-chart-container">
                      <SpeedChart
                        title={`Net Energy (KWh)`}
                        minValue={
                          energy?.data1?.reduce(
                            (acc, curr, index) =>
                              index !== 0
                                ? Math.min(acc, curr.net_energy)
                                : acc,
                            Infinity
                          ) -
                            (energy?.data1?.reduce(
                              (acc, curr, index) =>
                                index !== 0
                                  ? Math.min(acc, curr.net_energy)
                                  : acc,
                              Infinity
                            ) %
                              100000) || 10000000
                        }
                        maxValue={
                          energy?.data1?.reduce(
                            (acc, curr, index) =>
                              index !== 0
                                ? Math.max(acc, curr.net_energy)
                                : acc,
                            -Infinity
                          ) +
                            100000 -
                            (energy?.data1?.reduce(
                              (acc, curr, index) =>
                                index !== 0
                                  ? Math.max(acc, curr.net_energy)
                                  : acc,
                              -Infinity
                            ) %
                              100000) || 20000000
                        }
                        value={
                          energy?.data1?.find(
                            (e) => e.month === initialMonth.month1
                          )?.net_energy || 0
                        }
                      />
                      <SpeedChart
                        title={`Contractual Energy (KWh)`}
                        minValue={
                          energy?.data1?.reduce(
                            (acc, curr, index) =>
                              index !== 0
                                ? Math.min(acc, curr.contractual_energy)
                                : acc,
                            Infinity
                          ) -
                            (energy?.data1?.reduce(
                              (acc, curr, index) =>
                                index !== 0
                                  ? Math.min(acc, curr.contractual_energy)
                                  : acc,
                              Infinity
                            ) %
                              100000) || 10000000
                        }
                        maxValue={
                          energy?.data1?.reduce(
                            (acc, curr, index) =>
                              index !== 0
                                ? Math.max(acc, curr.contractual_energy)
                                : acc,
                            -Infinity
                          ) +
                            100000 -
                            (energy?.data1?.reduce(
                              (acc, curr, index) =>
                                index !== 0
                                  ? Math.max(acc, curr.contractual_energy)
                                  : acc,
                              -Infinity
                            ) %
                              100000) || 20000000
                        }
                        value={
                          energy?.data1?.find(
                            (e) => e.month === initialMonth.month1
                          )?.contractual_energy || 0
                        }
                      />
                    </div>
                    <div
                      id="greenenco"
                      style={{ position: "relative", display: "none" }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          bottom: "0",
                          right: "0",
                          padding: "0 0.5rem 0.5rem 0",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span style={{ fontSize: "1.2rem" }}>&#169;</span>
                        <span>GreenEnco</span>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                {/* ###   GenOverview Container 2 end ### */}

                {/* ###   GenOverview Container 3 start ### */}

                <Grid
                  item
                  xl={11.8}
                  lg={11.8}
                  md={11.8}
                  sm={11.8}
                  xs={11.8}
                  // borderRadius={"14px"}
                  sx={{
                    background:
                      "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
                    margin: "0vmax auto 1vmax",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "1vmax",
                  }}
                >
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{
                      position: "relative",
                      backgroundColor: "#fff",
                      padding: "1vmax",
                      overflow: "auto",
                    }}
                    ref={speedometerRef2}
                  >
                    <CSVLink
                      data={energy?.data1?.reduce((acc, curr) => {
                        acc.push({
                          Month: curr.month,
                          ExcessShortfall: curr.ExcessORShortfall_kwh,
                          ExcessShortfallPercentage:
                            curr.ExcessORShortfall_Percentage,
                        });
                        return acc;
                      }, [])}
                      filename="data.csv"
                      className="hidden"
                      ref={downloadRef2}
                      target="_blank"
                    />

                    <div
                      style={{
                        padding: "0 1.5rem",
                        position: "sticky",
                        left: "0",
                        right: "0",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        zIndex: "1",
                      }}
                    >
                      <Printer2
                        clickhandler={() => downloadRef2.current.link.click()}
                        jpgDownload={() =>
                        {
                          document.getElementById(
                            "hide_content"
                          ).style.display = "none";
                            exportComponentAsJPEG(
                              speedometerRef2,
                              (document.getElementById(
                                "greenenco2"
                              ).style.display = "block")
                            );
                            document.getElementById(
                              "greenenco2"
                            ).style.display = "none";
                          }
                        }
                        svgDownload={async () => {
                          document.getElementById(
                            "hide_content"
                          ).style.display = "none";
                          const svgData = await saveToSvg(
                            speedometerRef2.current,
                            (document.getElementById(
                              "greenenco2"
                            ).style.display = "block")
                          );
                          document.getElementById("greenenco2").style.display =
                            "none";
                         
                          saveAs(svgData, "gaugemeter.svg");
                        }}
                      />
                      <select
                        name="level"
                        // id="level"
                        style={{
                          padding: "1vmin 1.5vmin",
                          textAlign: "center",
                          fontWeight: "bolder",
                          borderStyle: "solid",
                          borderColor: "#ED7D31",
                          borderWidth: "3px",
                          fontSize: "1rem",
                          backgroundColor: "#edeaea",
                        }}
                        onChange={(e) => {
                          setInitialMonth({
                            ...initialMonth,
                            month2: e.target.value,
                          });
                        }}
                      >
                        {/* ###  Monthly Value component for Gaugemeter ### */}

                        <SelectOptions />
                      </select>
                    </div>
                    <div className="speed-chart-container">
                      <SpeedChart
                        title={`Excess Shortfall (KWh)`}
                        minValue={
                          energy?.data1?.reduce(
                            (acc, curr, index) =>
                              index !== 0
                                ? Math.min(acc, curr.ExcessORShortfall_kwh)
                                : acc,
                            Infinity
                          ) -
                            100000 -
                            (energy?.data1?.reduce(
                              (acc, curr, index) =>
                                index !== 0
                                  ? Math.min(acc, curr.ExcessORShortfall_kwh)
                                  : acc,
                              Infinity
                            ) %
                              100000) || 10000000
                        }
                        maxValue={
                          energy?.data1?.reduce(
                            (acc, curr, index) =>
                              index !== 0
                                ? Math.max(acc, curr.ExcessORShortfall_kwh)
                                : acc,
                            -Infinity
                          ) +
                            100000 -
                            (energy?.data1?.reduce(
                              (acc, curr, index) =>
                                index !== 0
                                  ? Math.max(acc, curr.ExcessORShortfall_kwh)
                                  : acc,
                              -Infinity
                            ) %
                              100000) || 20000000
                        }
                        value={
                          energy?.data1?.find(
                            (e) => e.month === initialMonth.month2
                          )?.ExcessORShortfall_kwh || 0
                        }
                      />
                      <SpeedChart
                        title={`Excess/Shortfall  (%)`}
                        minValue={
                          energy?.data1?.reduce(
                            (acc, curr, index) =>
                              index !== 0
                                ? Math.min(
                                    acc,
                                    curr.ExcessORShortfall_Percentage
                                  )
                                : acc,
                            Infinity
                          ) -
                          10 -
                          (energy?.data1?.reduce(
                            (acc, curr, index) =>
                              index !== 0
                                ? Math.min(
                                    acc,
                                    curr.ExcessORShortfall_Percentage
                                  )
                                : acc,
                            Infinity
                          ) %
                            10)
                        }
                        maxValue={
                          energy?.data1?.reduce(
                            (acc, curr, index) =>
                              index !== 0
                                ? Math.max(
                                    acc,
                                    curr.ExcessORShortfall_Percentage
                                  )
                                : acc,
                            -Infinity
                          ) +
                          10 -
                          (energy?.data1?.reduce(
                            (acc, curr, index) =>
                              index !== 0
                                ? Math.max(
                                    acc,
                                    curr.ExcessORShortfall_Percentage
                                  )
                                : acc,
                            -Infinity
                          ) %
                            10)
                        }
                        value={
                          energy?.data1?.find(
                            (e) => e.month === initialMonth.month2
                          )?.ExcessORShortfall_Percentage || 0
                        }
                      />
                    </div>
                    <div
                      id="greenenco2"
                      style={{ position: "relative", display: "none" }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          bottom: "0",
                          right: "0",
                          padding: "0 0.5rem 0.5rem 0",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span style={{ fontSize: "1.2rem" }}>&#169;</span>
                        <span>GreenEnco</span>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                {/* ###   GenOverview Container 3 end ### */}
                {/* ###   GenOverview Container 4 start ### */}

                <Grid
                  item
                  xl={11.8}
                  lg={11.8}
                  md={11.8}
                  sm={11.8}
                  xs={11.8}
                  // borderRadius={"14px"}
                  style={{
                    background:
                      "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
                    margin: "0vmax auto 1vmax",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "1vmax",
                  }}
                >
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{
                      position: "relative",
                      backgroundColor: "#fff",
                      padding: "1vmax",
                      overflow: "auto",
                    }}
                    ref={speedometerRef3}
                  >
                    <CSVLink
                      data={energy?.data1?.reduce((acc, curr) => {
                        acc.push({
                          Month: curr.month,
                          ActualPR: curr.Actual_pr,
                          AcLoss: curr.AC_Loss,
                        });
                        return acc;
                      }, [])}
                      filename="data.csv"
                      className="hidden"
                      ref={downloadRef3}
                      target="_blank"
                    />
                    <div
                      style={{
                        padding: "0 1.5rem",
                        position: "sticky",
                        left: "0",
                        right: "0",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        zIndex: "1",
                      }}
                    >
                      <Printer2
                        clickhandler={() => downloadRef3.current.link.click()}
                        jpgDownload={() =>
                        {
                          document.getElementById(
                            "hide_content"
                          ).style.display = "none";
                            exportComponentAsJPEG(
                              speedometerRef3,
                              (document.getElementById(
                                "greenenco3"
                              ).style.display = "block")
                            );
                            document.getElementById(
                              "greenenco3"
                            ).style.display = "none";
                          }
                        }
                        svgDownload={async () => {
                          document.getElementById(
                            "hide_content"
                          ).style.display = "none";
                            const svgData = await saveToSvg(
                              speedometerRef3.current,
                              (document.getElementById(
                                "greenenco3"
                              ).style.display = "block")
                            );
                            document.getElementById(
                              "greenenco3"
                            ).style.display = "none";
                            
                            saveAs(svgData, "gaugemeter.svg");
                        }}
                      />
                      <select
                        name="level"
                        // id="level"
                        style={{
                          padding: "1vmin 1.5vmin",
                          textAlign: "center",
                          fontWeight: "bolder",
                          borderStyle: "solid",
                          borderColor: "#ED7D31",
                          borderWidth: "3px",
                          fontSize: "1rem",
                          backgroundColor: "#edeaea",
                        }}
                        onChange={(e) => {
                          setInitialMonth({
                            ...initialMonth,
                            month3: e.target.value,
                          });
                        }}
                      >
                        {/* ###  Monthly Value component for Gaugemeter ### */}

                        <SelectOptions />
                      </select>
                    </div>
                    <div className="speed-chart-container">
                      <SpeedChart
                        title={`Actual PR (%)`}
                        minValue={
                          energy?.data1?.reduce(
                            (acc, curr, index) =>
                              index !== 0 ? Math.min(acc, curr.Actual_pr) : acc,
                            Infinity
                          ) -
                          10 -
                          (energy?.data1?.reduce(
                            (acc, curr, index) =>
                              index !== 0 ? Math.min(acc, curr.Actual_pr) : acc,
                            Infinity
                          ) %
                            10)
                        }
                        maxValue={
                          energy?.data1?.reduce(
                            (acc, curr, index) =>
                              index !== 0 ? Math.max(acc, curr.Actual_pr) : acc,
                            -Infinity
                          ) +
                          10 -
                          (energy?.data1?.reduce(
                            (acc, curr, index) =>
                              index !== 0 ? Math.max(acc, curr.Actual_pr) : acc,
                            -Infinity
                          ) %
                            10)
                        }
                        value={
                          energy?.data1?.find(
                            (e) => e.month === initialMonth.month3
                          )?.Actual_pr || 0
                        }
                      />
                      <SpeedChart
                        title={`AC Line Loss (%)`}
                        minValue={Math.round(
                          energy?.data1?.reduce(
                            (acc, curr, index) =>
                              index !== 0 ? Math.min(acc, curr.AC_Loss) : acc,
                            Infinity
                          )
                        )}
                        maxValue={Math.round(
                          energy?.data1?.reduce(
                            (acc, curr, index) =>
                              index !== 0 ? Math.max(acc, curr.AC_Loss) : acc,
                            -Infinity
                          )
                        )}
                        value={
                          energy?.data1?.find(
                            (e) => e.month === initialMonth.month3
                          )?.AC_Loss || 0
                        }
                      />
                    </div>
                    <div
                      id="greenenco3"
                      style={{ position: "relative", display: "none" }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          bottom: "0",
                          right: "0",
                          padding: "0 0.5rem 0.5rem 0",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span style={{ fontSize: "1.2rem" }}>&#169;</span>
                        <span>GreenEnco</span>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                {/* ###   GenOverview Container 4 end ### */}
                {/* ###   GenOverview Container 5 start ### */}

                <Grid
                  item
                  xl={11.8}
                  lg={11.8}
                  md={11.8}
                  sm={11.8}
                  xs={11.8}
                  // borderRadius={"14px"}
                  style={{
                    background:
                      "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
                    margin: "0 auto 1vmax",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "1vmax",
                  }}
                >
                  <Grid
                    container
                    alignItems={"center"}
                    justifyContent={"center"}
                    gap={"1vmax"}
                  >
                    <Grid
                      item
                      xl={5.9}
                      lg={5.9}
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{
                        position: "relative",
                        backgroundColor: "#fff",
                        padding: "1vmax",
                        overflow: "auto",
                      }}
                    >
                      <div
                        style={{
                          padding: "0.5rem 1.5rem",
                          position: "sticky",
                          left: "0",
                          right: "0",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          zIndex: "1",
                        }}
                      >
                        <h5>
                          Net Energy (KWh) vs Contratual Energy (KWh) vs
                          Excess/Shortfall (%)
                        </h5>
                        <Printer2
                          clickhandler={() => downloadRef4.current.link.click()}
                          jpgDownload={() => {
                            document.getElementsByClassName(
                              "cpy_right"
                            )[0].style.display = "block";
                            exportComponentAsJPEG(graphRef1, {
                              fileName: "graph",
                            });
                            document.getElementsByClassName(
                              "cpy_right"
                            )[0].style.display = "none";
                          }}
                          svgDownload={async () => {
                            document.getElementsByClassName(
                              "cpy_right"
                            )[0].style.display = "block";
                            const svgData = await saveToSvg(
                              graphRef1.current.container
                            );
                            document.getElementsByClassName(
                              "cpy_right"
                            )[0].style.display = "none";
                            saveAs(svgData, "graph.svg");
                          }}
                        />
                      </div>
                      <CSVLink
                        data={
                          energy?.data2?.reduce((acc, curr) => {
                            acc.push({
                              Month: curr.name,
                              NetEnergy: curr.netEnergy,
                              ContractualEnergy: curr.contractualEnergy,
                              ExcessShortfall: curr.shortfall,
                            });
                            return acc;
                          }, []) || []
                        }
                        filename="data.csv"
                        className="hidden"
                        target="_blank"
                        ref={downloadRef4}
                      />

                      <div className="generation-overview-responsive-container-1">
                        <ResponsiveContainer>
                          <ComposedChart
                            barCategoryGap={"20%"}
                            ref={graphRef1}
                            data={energy?.data2 || []}
                            margin={{
                              top: 5,
                              right: 5,
                              bottom: 5,
                              left: 5,

                              // top: "0.5vmin",
                              // right: "2vmin",
                              // bottom: "0.5vmin",
                              // left: "2vmin",
                            }}
                          >
                            <CartesianGrid stroke="grey" strokeWidth={0.3} />

                            <XAxis
                              dataKey="name"
                              fontSize={"0.8rem"}
                              fontWeight={600}
                              tickLine={false}
                              axisLine={false}
                              tickMargin={5}
                            />
                            <YAxis
                              fontSize={"0.8rem"}
                              fontWeight={600}
                              tickLine={false}
                              tickMargin={5}
                              tickCount={7}
                              tickFormatter={(v) =>
                                v >= 1000
                                  ? parseFloat(v / 1000).toFixed(0) + "k"
                                  : v
                              }
                              yAxisId="left-axis"
                              // label={<AxisLabel axisType='yAxis' x={-23} y={0}>Energy</AxisLabel>}
                              label={{
                                value: "Energy",
                                angle: -90,
                                position: "insideBottomLeft",
                                // dx: -10,
                                fontWeight: 600,
                                fontSize: "0.8rem",
                              }}
                              dataKey={() =>
                                Math.ceil(
                                  energy?.data2?.reduce(
                                    (acc, curr, index) =>
                                      Math.max(
                                        curr.netEnergy,
                                        curr.contractualEnergy,
                                        acc
                                      ),
                                    -Infinity
                                  )
                                ) + 100000
                              }
                              domain={[
                                Math.floor(
                                  energy?.data2?.reduce(
                                    (acc, curr, index) =>
                                      Math.min(
                                        curr.netEnergy,
                                        curr.contractualEnergy,
                                        acc
                                      ),
                                    Infinity
                                  )
                                ) - 100000,
                                "dataMax",
                              ]}
                            />
                            <YAxis
                              fontSize={"0.8rem"}
                              fontWeight={600}
                              tickLine={false}
                              tickMargin={8}
                              minTickGap={2}
                              yAxisId="right-axis"
                              orientation="right"
                              // label={<AxisLabel axisType='yAxis' x={42} y={355}>Excess/Shortfall</AxisLabel>}
                              label={{
                                value: "Excess/Shortfall",
                                angle: 90,
                                position: "insideBottomRight",
                                // dy: -110,
                                // dx: 10,
                                fontSize: "0.8rem",
                                fontWeight: "600",
                              }}
                            />
                            <Tooltip
                              cursor={{ fill: "none" }}
                              content={
                                <CustomizeTootip
                                  active={false}
                                  payload={[]}
                                  label={""}
                                  TooltipValues={[
                                    "Net Energy",
                                    "Contractual Energy",
                                    "Excess/Shortfall",
                                  ]}
                                />
                              }
                            />
                            <Legend
                              content={
                                <CustomizeLegend
                                  active={false}
                                  payload={[]}
                                  LegendValues={[
                                    "Net Energy",
                                    "Contractual Energy",
                                    "Excess/Shortfall",
                                  ]}
                                  data={showEnergyData}
                                  setData={setShowEnergyData}
                                  styles={{ fontSize: "0.8rem" }}
                                />
                              }
                            />
                            <Bar
                              hide={showEnergyData.netEnergy ? false : true}
                              dataKey="netEnergy"
                              // barSize={10}
                              // fill="#122b4f"
                              fill={`${colors3[0]}`}
                              yAxisId="left-axis"
                            />
                            <Bar
                              hide={
                                showEnergyData.contractualEnergy ? false : true
                              }
                              dataKey="contractualEnergy"
                              // barSize={10}
                              // fill="#ed7d31"
                              fill={`${colors3[1]}`}
                              yAxisId="left-axis"
                            />
                            <Line
                              hide={showEnergyData.shortFall ? false : true}
                              type="monotone"
                              dataKey="shortfall"
                              strokeWidth={3}
                              stroke={`${colors1[4]}`}
                              yAxisId="right-axis"
                            />
                          </ComposedChart>
                        </ResponsiveContainer>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xl={5.9}
                      lg={5.9}
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{
                        position: "relative",
                        backgroundColor: "#fff",
                        padding: "1vmax",
                        overflow: "auto",
                      }}
                    >
                      <div
                        style={{
                          padding: "0.5rem 1.5rem",
                          position: "sticky",
                          left: "0",
                          right: "0",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          zIndex: "1",
                        }}
                      >
                        <h5>Actual PR (%)</h5>
                        <Printer2
                          clickhandler={() => downloadRef5.current.link.click()}
                          jpgDownload={() => {
                            document.getElementsByClassName(
                              "cpy_right"
                            )[1].style.display = "block";
                            exportComponentAsJPEG(graphRef2, {
                              fileName: "graph",
                            });
                            document.getElementsByClassName(
                              "cpy_right"
                            )[1].style.display = "none";
                          }}
                          svgDownload={async () => {
                            document.getElementsByClassName(
                              "cpy_right"
                            )[1].style.display = "block";
                            const svgData = await saveToSvg(
                              graphRef2.current.container
                            );
                            document.getElementsByClassName(
                              "cpy_right"
                            )[1].style.display = "none";

                            saveAs(svgData, "graph.svg");
                          }}
                        />
                      </div>
                      <CSVLink
                        data={
                          energy?.data3?.reduce((acc, curr) => {
                            acc.push({
                              Month: curr.name,
                              ActualPR: curr.Actual_pr,
                            });
                            return acc;
                          }, []) || []
                        }
                        filename="data.csv"
                        className="hidden"
                        target="_blank"
                        ref={downloadRef5}
                      />

                      <div className="generation-overview-responsive-container-2">
                        <ResponsiveContainer>
                          <ComposedChart
                            barCategoryGap={"20%"}
                            ref={graphRef2}
                            margin={{
                              top: 5,
                              bottom: 5,
                              left: 5,
                              right: 5,
                            }}
                            data={energy?.data3 || []}
                          >
                            <XAxis
                              fontSize={"0.8rem"}
                              fontWeight={600}
                              dataKey="name"
                              tickLine={false}
                              axisLine={false}
                              tickMargin={5}
                            />
                            {/* <CartesianGrid stroke="#f5f5f5" strokeDasharray="3 3" /> */}
                            <CartesianGrid stroke="grey" strokeWidth={0.3} />

                            <YAxis
                              type="number"
                              fontSize={"0.8rem"}
                              fontWeight={600}
                              tickLine={false}
                              tickMargin={5}
                              tickCount={6}
                              dataKey={() =>
                                Math.ceil(
                                  energy?.data3?.reduce(
                                    (acc, curr, index) =>
                                      curr.Actual_pr > acc
                                        ? curr.Actual_pr
                                        : acc,
                                    -Infinity
                                  )
                                ) + 1
                              }
                              domain={[
                                Math.floor(
                                  energy?.data3?.reduce(
                                    (acc, curr, index) =>
                                      curr.Actual_pr < acc
                                        ? curr.Actual_pr
                                        : acc,
                                    Infinity
                                  )
                                ) - 1,
                                "dataMax",
                              ]}
                            />
                            {/* <defs>
                                            {energy?.data3?.map((color, index) => (
                                         <linearGradient id={`colorUv${index}`} x1='0' y1='0' x2='100%' y2='0' spreadMethod='reflect'>
                                         <stop offset='0' stopColor='#1a1a1a' />
                                          <stop offset='1' stopColor={colors3[0]} />
                                            </linearGradient>
                                            ))} 
                                          </defs> */}
                            <Legend
                              content={
                                <CustomizeLegend
                                  active={false}
                                  payload={[]}
                                  label={""}
                                  LegendValues={["Actual PR"]}
                                  data={showPRData}
                                  setData={setShowPRData}
                                  styles={{ fontSize: "0.8rem" }}
                                />
                              }
                            />
                            <Tooltip
                              cursor={{ fill: "none" }}
                              content={
                                <CustomizeTootip
                                  active={false}
                                  payload={[]}
                                  label={""}
                                  TooltipValues={["Actual PR"]}
                                />
                              }
                              contentStyle={{ fontSize: "0.7rem" }}
                            />
                            {
                              <Bar
                                hide={showPRData.showPR ? false : true}
                                dataKey="Actual_pr"
                                fill={colors3[0]}
                                onTransitionEnd={"1s all"}
                              >
                                {/* {energy?.data3?.map((entry, index) => (
                                          <Cell key={`cell-${index}`} fill={`url(#colorUv${index})`} />
                                         ))} */}
                                <LabelList
                                  style={{ zIndex: 6 }}
                                  dataKey="Actual_pr"
                                  position="top"
                                  fontSize={"0.8rem"}
                                  fontWeight={600}
                                  fontFamily="Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;"
                                />
                              </Bar>
                            }
                          </ComposedChart>
                        </ResponsiveContainer>
                      </div>
                    </Grid>
                  </Grid>

                  {/* ###   GenOverview Container 5 end ### */}
                </Grid>
              </Grid>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default GenOverView2;
