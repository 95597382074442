// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* color-throbber-sun #fcd605; */

/* Code for Loder which is used in Login, signup, Forgotpassword and Otp verify component */

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #FFF;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  font-weight: 900;
}
.loader::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-left: 4px solid #FF3D00;
  border-bottom: 4px solid transparent;
  animation: rotation 0.5s linear infinite reverse;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 




/* Graph Loader */

/* HTML: <div class="loader"></div> */
.graph-loader {
  width: -moz-fit-content;
  width: fit-content;
  font-weight: bold;
  font-family: monospace;
  font-size: 3vmin;
  clip-path: inset(0 3ch 0 0);
  animation: l4 1s steps(4) infinite;
}
.graph-loader:before {
  content:"Loading..."
}
@keyframes l4 {to{clip-path: inset(0 -1ch 0 0)}}


`, "",{"version":3,"sources":["webpack://./src/components/Loader.css"],"names":[],"mappings":"AAAA,gCAAgC;;AAEhC,2FAA2F;;AAE3F;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,qBAAqB;EACrB,0BAA0B;EAC1B,mCAAmC;EACnC,sBAAsB;EACtB,sCAAsC;EACtC,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,sBAAsB;EACtB,kBAAkB;EAClB,OAAO;EACP,MAAM;EACN,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,8BAA8B;EAC9B,oCAAoC;EACpC,gDAAgD;AAClD;AACA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;;;;;AAKA,iBAAiB;;AAEjB,qCAAqC;AACrC;EACE,uBAAkB;EAAlB,kBAAkB;EAClB,iBAAiB;EACjB,sBAAsB;EACtB,gBAAgB;EAChB,2BAA2B;EAC3B,kCAAkC;AACpC;AACA;EACE;AACF;AACA,eAAe,GAAG,4BAA4B,CAAC","sourcesContent":["/* color-throbber-sun #fcd605; */\n\n/* Code for Loder which is used in Login, signup, Forgotpassword and Otp verify component */\n\n.loader {\n  width: 48px;\n  height: 48px;\n  border-radius: 50%;\n  display: inline-block;\n  border-top: 4px solid #FFF;\n  border-right: 4px solid transparent;\n  box-sizing: border-box;\n  animation: rotation 1s linear infinite;\n  font-weight: 900;\n}\n.loader::after {\n  content: '';  \n  box-sizing: border-box;\n  position: absolute;\n  left: 0;\n  top: 0;\n  width: 48px;\n  height: 48px;\n  border-radius: 50%;\n  border-left: 4px solid #FF3D00;\n  border-bottom: 4px solid transparent;\n  animation: rotation 0.5s linear infinite reverse;\n}\n@keyframes rotation {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n} \n\n\n\n\n/* Graph Loader */\n\n/* HTML: <div class=\"loader\"></div> */\n.graph-loader {\n  width: fit-content;\n  font-weight: bold;\n  font-family: monospace;\n  font-size: 3vmin;\n  clip-path: inset(0 3ch 0 0);\n  animation: l4 1s steps(4) infinite;\n}\n.graph-loader:before {\n  content:\"Loading...\"\n}\n@keyframes l4 {to{clip-path: inset(0 -1ch 0 0)}}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
