import { colors3 } from "../colors/color";

/* #####  Function for generate random number between 0 to 9  ##### */

export const getRandomNumber = () => {
  try {
    return Math.round(Math.random() * 9);
  } catch (error) {
    console.log(error);
  }
};

/* #####  Function for get random Letter Among "ABCDEFabcdef"  ##### */

export const getRandomLetter = () => {
  try {
    const charList = "ABCDEFabcdef";
    const index = Math.round(Math.random() * (charList?.length - 1));
    return charList[index];
  } catch (error) {
    console.log(error);
  }
};

/* #####  Function for get Hexa color code  ##### */

export const getColourPicker = (index) => {
  const hexColourLength = 6;
  let colourCode = "#";
  try {
    if (index < colors3.length) return colors3[index];
    else {
      for (let i = 0; i < hexColourLength; i++) {
        const isLetter = Math.round(Math.random());
        if (isLetter) {
          colourCode = colourCode + getRandomLetter();
        } else {
          colourCode = colourCode + getRandomNumber();
        }
      }
      return colourCode;
    }
  } catch (error) {
    console.log(error);
  }
};

/* #####  Function to generate data url to download it in jpg format  ##### */

export const saveToSvg = (svg, width, height) => {
  return new Promise((resolve, reject) => {
    let canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    let ctx = canvas.getContext("2d");
    ctx.fillStyle = "#ffffff";
    ctx.fillRect(0, 0, width, height);

    let xml = new XMLSerializer().serializeToString(svg);
    let dataUrl = "data:image/svg+xml;utf8," + encodeURIComponent(xml);
    resolve(dataUrl);
  });
};

/* #####  Function to convert number to ReadableValue using separater like -->,  ##### */

export const convertToReadableValue = (val) => {
  let string = "" + val;
  let arr = string.split("");
  arr.splice(1, 0, ",");
  arr.splice(4, 0, ",");
  arr.splice(7, 0, ",");
  return arr.join("");
};

/* #####  To set Default axis label value in graphs  ##### */

export const AxisLabel = ({
  axisType,
  x = 10,
  y = 90,
  width = 50,
  height = 50,
  stroke,
  children,
}) => {
  const isVert = axisType === "yAxis";
  const cx = isVert ? x : x + width / 2;
  const cy = isVert ? height / 2 + y : y + height + 10;
  const rot = isVert ? `270 ${cx} ${cy}` : 0;
  return (
    <text
      className="animation_label"
      style={{ boxSizing: "border-box" }}
      enableBackground={true}
      x={`${cx}%`}
      y={`${cy}%`}
      transform={`rotate(${rot})`}
      textAnchor="middle"
      stroke={stroke}
    >
      {children}
    </text>
  );
};

export const getMonthValue = (val) => {
  let res = "January";
  switch (val) {
    case 0:
      res = "January";
      break;
    case 1:
      res = "February";
      break;
    case 2:
      res = "March";
      break;
    case 3:
      res = "April";
      break;
    case 4:
      res = "May";
      break;
    case 5:
      res = "June";
      break;
    case 6:
      res = "July";
      break;
    case 7:
      res = "August";
      break;
    case 8:
      res = "September";
      break;
    case 9:
      res = "October";
      break;
    case 10:
      res = "November";
      break;
    default:
      res = "December";
      break;
  }

  return res;
};
