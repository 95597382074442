import React, { useEffect, useState } from "react";
import "./ProjOverView.css";
import { Link } from "react-router-dom";
import PageURL from "../../../../constants/PageURL";
import transformer_doc_1 from "../../../../constants/transformer/Transformer DATASHEET.pdf";
// import transformer_doc_2 from "../../../../constants/transformer/100 KVA Aux Trafo - WBREDA.pdf"
// import cabel_doc_1 from "../../../../constants/cables/LUMINO JUPITER SOLAR FOR WBSWDCL (D-GM-0619-123) -TP-GF (R-2)(04.07.2019....xls"
// import cabel_doc_2 from "../../../../constants/cables/QAP (WBREDA) -APAR.pdf"
import inverter_doc_1 from "../../../../constants/inverters/SINENG Inv Datasheet.pdf";
import inverter_doc_2 from "../../../../constants/inverters/TBEA Inv Datasheet.pdf";
import module_doc_1 from "../../../../constants/modules/Adani solar datasheet.pdf";
import module_doc_2 from "../../../../constants/modules/Vikram Module datasheet.pdf";
import sld_ac_doc_1 from "../../../../constants/SLD/AC SLD/AC SLD.pdf";
import sld_dc_doc_1 from "../../../../constants/SLD/DC SLD/DC SLD.pdf";
import pvsyst_doc_1 from "../../../../constants/PVsyst/PVsyst NTPC 300 MW Nokhra-Active Power Restriction 304 MW 1 1.pdf";

import { useDispatch, useSelector } from "react-redux";
import {
  downloadAsbuiltDocuments,
  getAsbuiltDocuments,
} from "../../../../actions/energyAction";
import GraphLoader from "../../../../components/GraphLoader";
import axios from "axios";
import { download_asbuilt_document_reset } from "../../../../constants/dataConstants";
/* ###  Document paths and other details  ### */

const pathData = [
  {
    type: "Datasheet",
    name: "Modules",
    lname: "Inverter",
    path: [module_doc_1, module_doc_2],
  },
  {
    type: "Datasheet",
    name: "Inverter",
    lname: "Inverter",
    path: [inverter_doc_1, inverter_doc_2],
  },
  {
    type: "Datasheet",
    lname: "Inverter",
    name: "Transformer",
    path: [transformer_doc_1],
  },
  {
    type: "Cables",
    name: "Cables",
    lname: "Cables",
    path: [],
  },
  {
    type: "SLD",
    name: "Sld_Ac",
    lname: "SLD AC",
    path: [sld_ac_doc_1],
  },
  {
    type: "SLD",
    name: "Sld_Dc",
    lname: "SLD DC",
    path: [sld_dc_doc_1],
  },
  {
    type: "PVsyst",
    name: "PVsyst",
    lname: "PVsyst",
    path: [pvsyst_doc_1],
  },
  {
    type: "String_Configuration",
    name: "String_Configuration",
    lname: "String Configuration",
    path: [],
  },
  {
    type: "Warranty",
    name: "Warranty",
    lname: "Warranty",
    path: [],
  },
  {
    type: "OM_Contract",
    name: "OM_Contract",
    lname: "OM Contract",
    path: [],
  },
];

/* ###  General Details Component  ### */

const AsBuiltDetails = ({ databaseStr }) => {
  const { asbuiltDocument, loading, loading2, downloadAsbuiltDocument } =
    useSelector((state) => state.asbuiltDocument);
  const [path, setPath] = useState("Modules");
  const [showModule, setShowModule] = useState(false);
  const [showSLD, setShowSLD] = useState(false);

  const [documentDetail, setDocumentDetail] = useState({
    category: "Datasheet",
    name: "Modules",
  });

  /* ###  Initial document path and name  ### */
  const [documentName, setDocumentName] = useState({
    type: "Datasheet",
    name: "Modules",
  });

  /* ###  States to highlight links  ### */

  const [checkedData, setCheckedData] = useState({
    Datasheet: true,
    Cables: false,
    SLD: false,
    String_Configuration: false,
    Warranty: false,
    PVsyst: false,
    OM_Contract: false,
  });

  const dispatch = useDispatch();

  /* ###  Change Document path Handler  ### */

  const selectPathHandler = (category, name) => {
    if (name != documentDetail.name) {
      dispatch(getAsbuiltDocuments(category, name, databaseStr));
    }
    setDocumentDetail({ ...documentDetail, category: category, name: name });
  };

  /* ###  To  highlight open links Handler    ### */

  const changeLinkHandler = (name) => {
    let newdata = {};
    for (let i in checkedData) {
      if (name === i) newdata[name] = true;
      else newdata[i] = false;
    }
    setCheckedData(newdata);
  };

  /* ###  To  Download Document Handler    ### */

  const downloadDocument = async (fileName) => {
    dispatch(
      downloadAsbuiltDocuments({
        projectName: databaseStr,
        category: documentDetail.category,
        name: documentDetail.name,
        fileName,
      })
    );
  };

  useEffect(() => {
    if (databaseStr && !asbuiltDocument) {
      dispatch(
        getAsbuiltDocuments(
          documentDetail.category,
          documentDetail.name,
          databaseStr
        )
      );
    }

    if (downloadAsbuiltDocument) {
      const blob = new Blob([downloadAsbuiltDocument?.data], {
        type: "application/pdf",
      });
      const fileURL = window.URL.createObjectURL(blob);
      const aLink = document.createElement("a");
      aLink.href = fileURL;
      aLink.download = downloadAsbuiltDocument?.fileName || "download.pdf"; // Specify the file name for the download
      document.body.appendChild(aLink); // Append anchor to body
      aLink.click(); // Trigger download
      document.body.removeChild(aLink); // Remove anchor after download
      window.URL.revokeObjectURL(fileURL);
      dispatch({ type: download_asbuilt_document_reset });
    }
  }, [downloadAsbuiltDocument, dispatch]);
  return (
    <div className="asbuilt_div">
      <div>
        <span>GreenEnco</span>
        <span>Creating A Better World In A Changing Climate</span>
      </div>
      <div>
        <div>
          <p className="categories">Categories</p>
          <ul>
            <Link
              to={PageURL.INDIA_PROJ_OVERVIEW}
              onClick={() => changeLinkHandler("Datasheet")}
              style={{ color: checkedData.Datasheet ? "red" : "black" }}
              onMouseOver={() => setShowModule(true)}
              onMouseOut={() => setShowModule(false)}
            >
              Datasheet
              <div
                className="module_div"
                style={{
                  width: showModule ? "120%" : "0",
                  padding: showModule ? "0.5vmax 0.8vmax" : "0",
                  boxShadow: showModule ? "1px 1px 1px 2px black" : "none",
                }}
              >
                <Link
                  onClick={() => {
                    selectPathHandler("Datasheet", "Modules");
                  }}
                >
                  Modules
                </Link>
                <Link
                  onClick={() => {
                    selectPathHandler("Datasheet", "Inverter");
                  }}
                >
                  Inverter
                </Link>
                <Link
                  onClick={() => {
                    selectPathHandler("Datasheet", "Transformer");
                  }}
                >
                  Transformer
                </Link>
              </div>
            </Link>
            <Link
              onClick={() => {
                changeLinkHandler("Cables");
                selectPathHandler("Cables", "Cables");
              }}
              style={{ color: checkedData.Cables ? "red" : "black" }}
            >
              Cables
            </Link>
            <Link
              onClick={() => changeLinkHandler("SLD")}
              style={{ color: checkedData.SLD ? "red" : "black" }}
              onMouseOver={() => setShowSLD(true)}
              onMouseOut={() => setShowSLD(false)}
            >
              SLD
              <div
                className="sld_div"
                style={{
                  width: showSLD ? "150%" : "0",
                  padding: showSLD ? "0.5vmax 1.5vmax" : "0",
                  boxShadow: showSLD ? "1px 1px 1px 2px black" : "none",
                }}
              >
                <Link
                  onClick={() => {
                    selectPathHandler("SLD", "DC SLD");
                  }}
                >
                  DC SLD
                </Link>
                <Link
                  onClick={() => {
                    selectPathHandler("SLD", "AC SLD");
                  }}
                >
                  AC SLD
                </Link>
              </div>
            </Link>
            <Link
              onClick={() => {
                selectPathHandler(
                  "String Configuration",
                  "String Configuration"
                );
                changeLinkHandler("String_Configuration");
              }}
              style={{
                color: checkedData.String_Configuration ? "red" : "black",
              }}
            >
              String Configuration
            </Link>
            <Link
              style={{ color: checkedData.Warranty ? "red" : "black" }}
              onClick={() => {
                selectPathHandler("Warranty", "Warranty");
                changeLinkHandler("Warranty");
              }}
            >
              Warranty
            </Link>
            <Link
              style={{ color: checkedData.PVsyst ? "red" : "black" }}
              onClick={() => {
                selectPathHandler("PVsyst", "PVsyst");

                changeLinkHandler("PVsyst");
              }}
            >
              PVsyst
            </Link>
            <Link
              style={{ color: checkedData.OM_Contract ? "red" : "black" }}
              onClick={() => {
                selectPathHandler("OM Contract", "OM Contract");
                changeLinkHandler("OM_Contract");
              }}
            >
              O&M Contract
            </Link>
          </ul>
        </div>
        <div>
          {loading ? (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <GraphLoader />
            </div>
          ) : asbuiltDocument && asbuiltDocument.length > 0 ? (
            asbuiltDocument.map((el, i) => {
              return (
                <div key={i}>
                  <div>
                    <div style={{ height: "100%", width: "100%" }}>
                      <span style={{ fontSize: "1vmax", fontWeight: "600" }}>
                        Document Type :{" "}
                        <span style={{ fontWeight: "lighter" }}>
                          {el?.category}
                        </span>{" "}
                      </span>
                      <span style={{ fontSize: "1vmax", fontWeight: "600" }}>
                        Document Name :{" "}
                      </span>
                      <span
                        style={{
                          wordBreak: "break-word",
                          textAlign: "center",
                          fontSize: "1vmax",
                          padding: "0 0.5vmax",
                          fontWeight: "lighter",
                        }}
                      >
                        {el.fileName}
                      </span>
                    </div>
                    <div
                      id={el.fileType != "pdf" ? "excel_button" : "pdf_button"}
                      style={{
                        background:
                          el.fileType != "pdf" ? "#05c059" : "rgb(248, 95, 65)",
                      }}
                      onClick={() => downloadDocument(el?.fileName)}
                    >
                      <Link style={{ color: "black" }}>Download</Link> In
                      <i
                        className={`fa-solid fa-file-${
                          el.fileType != "pdf" ? "excel" : "pdf"
                        }`}
                      ></i>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <h1 style={{ fontSize: "1.5vmax" }}> Data Not Available </h1>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AsBuiltDetails;
