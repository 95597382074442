// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* #####  Code for Printer Component Which Download Graph  ##### */

.print_icon_div {
}
.links_div {
  position: absolute;
  right: 1.5rem;
  top: 0;
  box-sizing: border-box;
  display: flex;
  background-color: aliceblue;
  text-align: center;
  border-radius: 4px;
  flex-direction: column;
  gap: 0.5rem;
  /* box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset; */
  box-shadow: 1px 2px 2px black;
  font-size: 0.7rem;
  overflow: hidden;
  /* border: 1px solid #203864 ; */
  /* background: rgb(3, 99, 125);
  background: #fff; */
  height: auto;
  padding: 0.5rem 0.7rem;
}

.links_div a {
  white-space: nowrap;
  text-decoration: none;
  font-weight: 600;
}

.links_div a:hover {
  color: rgb(236, 33, 15);
  color: rgb(240, 65, 17);
  font-weight: 900;
}
`, "",{"version":3,"sources":["webpack://./src/components/printer.css"],"names":[],"mappings":"AAAA,kEAAkE;;AAElE;AACA;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,MAAM;EACN,sBAAsB;EACtB,aAAa;EACb,2BAA2B;EAC3B,kBAAkB;EAClB,kBAAkB;EAClB,sBAAsB;EACtB,WAAW;EACX,4GAA4G;EAC5G,6BAA6B;EAC7B,iBAAiB;EACjB,gBAAgB;EAChB,gCAAgC;EAChC;qBACmB;EACnB,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;EACnB,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;EACvB,uBAAuB;EACvB,gBAAgB;AAClB","sourcesContent":["/* #####  Code for Printer Component Which Download Graph  ##### */\n\n.print_icon_div {\n}\n.links_div {\n  position: absolute;\n  right: 1.5rem;\n  top: 0;\n  box-sizing: border-box;\n  display: flex;\n  background-color: aliceblue;\n  text-align: center;\n  border-radius: 4px;\n  flex-direction: column;\n  gap: 0.5rem;\n  /* box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset; */\n  box-shadow: 1px 2px 2px black;\n  font-size: 0.7rem;\n  overflow: hidden;\n  /* border: 1px solid #203864 ; */\n  /* background: rgb(3, 99, 125);\n  background: #fff; */\n  height: auto;\n  padding: 0.5rem 0.7rem;\n}\n\n.links_div a {\n  white-space: nowrap;\n  text-decoration: none;\n  font-weight: 600;\n}\n\n.links_div a:hover {\n  color: rgb(236, 33, 15);\n  color: rgb(240, 65, 17);\n  font-weight: 900;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
