import React, { useEffect, useRef, useState } from "react";
import {
  Area,
  AreaChart,
  Bar,
  CartesianGrid,
  ComposedChart,
  LabelList,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { saveAs } from "file-saver";
import { colors1, colors3 } from "../../../../colors/color";
import CustomizeTootip from "../../../../components/CustomizeTootip";
import CustomizeLegend from "../../../../components/CustomizeLegend";
import Printer2 from "../../../../components/Printer2";
import { exportComponentAsJPEG } from "react-component-export-image";
import { saveToSvg } from "../../../../constants/Utils";
import { CSVLink } from "react-csv";
import GraphLoader from "../../../../components/GraphLoader";

const PvsystContractual = ({ pvsystContractualEnergy, loading }) => {
  const csvDownloadRef = useRef(null);
  const graphRef = useRef(null);
  const [showBarData, setShowBarData] = useState({});

  useEffect(() => {
    if (pvsystContractualEnergy && pvsystContractualEnergy?.length > 0) {
      let obj = {};

      Object.keys(pvsystContractualEnergy[0]).forEach((e) => {
        if (e != "name") {
          obj[e] = true;
        }
      });
      obj["all"] = true;
      setShowBarData(obj);
    }
  }, [pvsystContractualEnergy]);

  return (
    <>
      <div
        style={{
          padding: "0.5rem 1.5rem",
          position: "sticky",
          left: "0",
          right: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          zIndex: "1",
        }}
      >
        <h5>PVsyst Energy (KWh) vs Contratual Energy (KWh)</h5>
        <Printer2
          clickhandler={() => csvDownloadRef.current.link.click()}
          jpgDownload={() =>
            setTimeout(async () => {
              document.getElementsByClassName("cpy_right")[3].style.display =
                "block";
              exportComponentAsJPEG(graphRef, {
                fileName: "graph",
              });
              document.getElementsByClassName("cpy_right")[3].style.display =
                "none";
            }, 100)
          }
          svgDownload={async () => {
            document.getElementsByClassName("cpy_right")[3].style.display =
              "block";
            const svgData = await saveToSvg(graphRef.current.container);
            document.getElementsByClassName("cpy_right")[3].style.display =
              "none";
            saveAs(svgData, "graph.svg");
          }}
        />
      </div>
      <CSVLink
        data={
          pvsystContractualEnergy?.reduce((acc, curr) => {
            acc.push({
              Month: curr.name,
              PVsystEnergy: curr["pvsystEnergy"],
              ContractualEnergy: curr["contractualEnergy"],
            });
            return acc;
          }, []) || []
        }
        filename="data.csv"
        className="hidden"
        target="_blank"
        ref={csvDownloadRef}
      />

      <div
        style={{
          minWidth:
            pvsystContractualEnergy?.length > 0
              ? pvsystContractualEnergy.length *
                  Object.keys(pvsystContractualEnergy[0]).length+
                 
                "vw"
              : "100%",
        }}
        className="detailed-responsive-container-4"
      >
        <ResponsiveContainer>

        {loading ? (
            <div
              style={{
                position: "absolute",
                top: "0",
                bottom: "0",
                right: "0",
                left: "0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <GraphLoader />
            </div>
          ) : (
          <ComposedChart
            margin={{
              top: 5,
              right: 5,
              bottom: 5,
              left: 5,
            }}
            barCategoryGap={"20%"}
            data={pvsystContractualEnergy || []}
            ref={graphRef}
          >
            <CartesianGrid stroke="grey" strokeWidth={0.3} />
            <XAxis
              dataKey="name"
              fontSize={"0.8rem"}
              fontWeight={600}
              tickLine={false}
              axisLine={false}
              tickMargin={5}
            />
            <YAxis
              fontSize={"0.8rem"}
              fontWeight={600}
              tickLine={false}
              tickMargin={5}
              tickFormatter={(v) =>
                v >= 1000 ? parseFloat(v / 1000).toFixed(0) + "k" : v
              }
              yAxisId="left-axis"
              //  label={{ value: "Energy", angle: -90,x:20, position: "insideLeft"}}
              // label={<AxisLabel axisType='yAxis' x={-17} y={-7}>Energy</AxisLabel>}
              dataKey={() =>
                Math.ceil(
                  pvsystContractualEnergy?.reduce(
                    (acc, curr, index) =>
                      Math.max(
                        curr["pvsystEnergy"],
                        curr["contractualEnergy"],
                        acc
                      ),
                    -Infinity
                  )
                ) + 100000
              }
              domain={[
                Math.floor(
                  pvsystContractualEnergy?.reduce(
                    (acc, curr, index) =>
                      Math.min(
                        curr["pvsystEnergy"],
                        curr["contractualEnergy"],
                        acc
                      ),
                    Infinity
                  )
                ) - 1000000,
                "dataMax",
              ]}
            />
            <Tooltip
              content={
                <CustomizeTootip
                  active={false}
                  payload={[]}
                  label={""}
                  TooltipValues={["PVsyst Energy", "Contractual Energy"]}
                />
              }
            />
            <Legend
              content={
                <CustomizeLegend
                  active={false}
                  payload={[]}
                  LegendValues={["PVsyst Energy", "Contractual Energy"]}
                  data={showBarData}
                  setData={setShowBarData}
                  styles={{ fontSize: "0.8rem" }}
                />
              }
            />
            {/* {pvsystContractualEnergy?.length > 0 &&
              Object.keys(pvsystContractualEnergy[0])?.map((e, index) => {
                if (e === "name") {
                  return null;
                }
                return (
                  <Bar
                  hide={showBarData[e] ? false : true}
                  dataKey={e}
                  fill={`${colors3[index]}`}
                  yAxisId="left-axis"
                />
                )
                
              })} */}
            <Bar
              hide={showBarData["pvsystEnergy"] ? false : true}
              dataKey={"pvsystEnergy"}
              fill={`${colors3[0]}`}
              yAxisId="left-axis"
            />{" "}
            <Bar
              hide={showBarData["contractualEnergy"] ? false : true}
              dataKey={"contractualEnergy"}
              fill={`${colors3[1]}`}
              yAxisId="left-axis"
            />
          </ComposedChart>
          )}
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default PvsystContractual;
