import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import Printer2 from "../../../../components/Printer2";
import { exportComponentAsJPEG } from "react-component-export-image";
import { saveToSvg } from "../../../../constants/Utils";
import SpeedChart from "../../../../components/SpeedChart";
import { saveAs } from "file-saver";
import DateRangeComp from "../../../../components/DateRangeComp";
import SelectOptions from "./SelectOptions";

const ExcessShortFall = ({ netContractualEnergyShortfall, loading }) => {
  const csvDownloadRef = useRef(null);
  const graphRef = useRef(null);

  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    if (
      netContractualEnergyShortfall &&
      netContractualEnergyShortfall?.length > 0
    ) {
      setSelectedDate(netContractualEnergyShortfall[0].name);
    }
  }, [netContractualEnergyShortfall]);

 
  return (
    <>
      <CSVLink
        data={[].reduce((acc, curr) => {
          acc.push({
            Month: curr.month,
            ExcessShortfall: curr.ExcessORShortfall_kwh,
            ExcessShortfallPercentage: curr.ExcessORShortfall_Percentage,
          });
          return acc;
        }, [])}
        filename="data.csv"
        className="hidden"
        ref={csvDownloadRef}
        target="_blank"
      />

      <div
        style={{
          padding: "0 1.5rem",
          position: "sticky",
          left: "0",
          right: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          zIndex: "1",
        }}
      >
        <div
          style={{
            padding: "0 1.5rem",
            position: "sticky",
            left: "0",
            right: "0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            zIndex: "1",
          }}
        >
          <select
            name="level"
            // id="level"
            style={{
              padding: "1vmin 1.5vmin",
              textAlign: "center",
              fontWeight: "bolder",
              borderStyle: "solid",
              borderColor: "#ED7D31",
              borderWidth: "3px",
              fontSize: "1rem",
              backgroundColor: "#edeaea",
            }}
            onChange={(e) => {
              setSelectedDate(e.target.value);
            }}
          >
            {/* ###  Monthly Value component for Gaugemeter ### */}

            <SelectOptions
              dataArray={
                netContractualEnergyShortfall?.map((e) => e.name) || []
              }
            />
          </select>
        </div>
        <Printer2
          clickhandler={() => csvDownloadRef.current.link.click()}
          jpgDownload={() => {
            document.getElementById("hide_content").style.display = "none";
            exportComponentAsJPEG(
              graphRef,
              (document.getElementById("greenenco2").style.display = "block")
            );
            document.getElementById("greenenco2").style.display = "none";
          }}
          svgDownload={async () => {
            document.getElementById("hide_content").style.display = "none";
            const svgData = await saveToSvg(
              graphRef.current,
              (document.getElementById("greenenco2").style.display = "block")
            );
            document.getElementById("greenenco2").style.display = "none";

            saveAs(svgData, "gaugemeter.svg");
          }}
        />
      </div>
      <div className="speed-chart-container">
        <SpeedChart
          title={`Excess Shortfall (KWh)`}
          minValue={
            netContractualEnergyShortfall?.reduce(
              (acc, curr, index) =>
                Math.min(acc, curr["shortFall_kwh"]) ,
              Infinity
            )+10000
          }
          maxValue={
            netContractualEnergyShortfall?.reduce(
              (acc, curr, index) =>
                 Math.max(acc, curr["shortFall_kwh"]) ,
              -Infinity
            )-10000
          }
          value={
            netContractualEnergyShortfall?.find(
              (e) => e.name === selectedDate
            )?.["shortFall_kwh"] || 0
          }
        />
        <SpeedChart
          title={`Excess/Shortfall  (%)`}
          minValue={
           Math.floor(netContractualEnergyShortfall?.reduce(
              (acc, curr, index) => Math.min(acc, curr["shortFall"]),
              Infinity
            ) )-1
          }
          maxValue={
           Math.ceil( netContractualEnergyShortfall?.reduce(
              (acc, curr, index) => Math.max(acc, curr["shortFall"]),
              -Infinity
            ) )+1
          }
          value={
            netContractualEnergyShortfall?.find(
              (e) => e.name === selectedDate
            )?.["shortFall"] || 0
          }
        />
      </div>
      <div id="greenenco2" style={{ position: "relative", display: "none" }}>
        <div
          style={{
            position: "absolute",
            bottom: "0",
            right: "0",
            padding: "0 0.5rem 0.5rem 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span style={{ fontSize: "1.2rem" }}>&#169;</span>
          <span>GreenEnco</span>
        </div>
      </div>
    </>
  );
};

export default ExcessShortFall;
