
/* #####  Constant URLs for different route  ##### */

const PageURL = {
  BASE: "/",
  SIGN_UP:"/user/register",
  FORGOT_PASSWORD:"/user/fogot-password",
  OTP_VERIFICATION:"/user/forgot-password/otp/verify",
  COUNTRYDEFAULT: "/dashboard",
  PROJECT_DETAILS: "/dashboard/project-details",
  PROJECT_ANALYTICS:"/dashboard/project-analytics",
  INDIA_GEN_SUMMARY: "/dashboard/india/generationsummary",
  INDIA_PROJ_OVERVIEW: "/dashboard/india/projectoverview",
  INDIA_PRES_MODEL_Loss_Flow: "/dashboard/india/prescriptivemodel/loss-flow",
  INDIA_PRES_MODEL_Fault_Tree: "/dashboard/india/prescriptivemodel/fault-tree",
  INDIA_DIAGONISTIC: "/dashboard/india/diagonistic",
  INDIA_DIAGONISTIC_DETAILED: "/dashboard/india/diagonistic/detailed",
  INDIA_LOSS_FLOW: "/dashboard/india/diagonistic/lossflow",
  INDIA_INVERTER_EFFICIENCY: "/dashboard/india/diagonistic/inverter-efficiency",

  INDIA_INVERTER1_SCB_SMB1: "/dashboard/india/diagonistic/inverter1/SCBSMB1",
  INDIA_INVERTER1_SCB_SMB2: "/dashboard/india/diagonistic/inverter1/SCBSMB2",
  INDIA_INVERTER1_SCB_SMB3: "/dashboard/india/diagonistic/inverter1/SCBSMB3",
  INDIA_INVERTER1_SCB_SMB4: "/dashboard/india/diagonistic/inverter1/SCBSMB4",
  INDIA_INVERTER1_SCB_SMB5: "/dashboard/india/diagonistic/inverter1/SCBSMB5",
  INDIA_INVERTER1_SCB_SMB6: "/dashboard/india/diagonistic/inverter1/SCBSMB6",
  INDIA_INVERTER1_SCB_SMB7: "/dashboard/india/diagonistic/inverter1/SCBSMB7",
  INDIA_INVERTER1_SCB_SMB8: "/dashboard/india/diagonistic/inverter1/SCBSMB8",
  INDIA_INVERTER1_SCB_SMB9: "/dashboard/india/diagonistic/inverter1/SCBSMB9",
  INDIA_INVERTER1_SCB_SMB10: "/dashboard/india/diagonistic/inverter1/SCBSMB10",
  INDIA_INVERTER1_SCB_SMB11: "/dashboard/india/diagonistic/inverter1/SCBSMB11",
  INDIA_INVERTER1_SCB_SMB12: "/dashboard/india/diagonistic/inverter1/SCBSMB12",
  INDIA_INVERTER1_HEATMAP_DIAGRAM: "/dashboard/india/diagonistic/inverter1/heatmap-diagram",

  INDIA_INVERTER2_SCB_SMB1: "/dashboard/india/diagonistic/inverter2/SCBSMB1",
  INDIA_INVERTER2_SCB_SMB2: "/dashboard/india/diagonistic/inverter2/SCBSMB2",
  INDIA_INVERTER2_SCB_SMB3: "/dashboard/india/diagonistic/inverter2/SCBSMB3",
  INDIA_INVERTER2_SCB_SMB4: "/dashboard/india/diagonistic/inverter2/SCBSMB4",
  INDIA_INVERTER2_SCB_SMB5: "/dashboard/india/diagonistic/inverter2/SCBSMB5",
  INDIA_INVERTER2_SCB_SMB6: "/dashboard/india/diagonistic/inverter2/SCBSMB6",
  INDIA_INVERTER2_SCB_SMB7: "/dashboard/india/diagonistic/inverter2/SCBSMB7",
  INDIA_INVERTER2_SCB_SMB8: "/dashboard/india/diagonistic/inverter2/SCBSMB8",
  INDIA_INVERTER2_SCB_SMB9: "/dashboard/india/diagonistic/inverter2/SCBSMB9",
  INDIA_INVERTER2_SCB_SMB10: "/dashboard/india/diagonistic/inverter2/SCBSMB10",
  INDIA_INVERTER2_SCB_SMB11: "/dashboard/india/diagonistic/inverter2/SCBSMB11",
  INDIA_INVERTER2_SCB_SMB12: "/dashboard/india/diagonistic/inverter2/SCBSMB12",
  INDIA_INVERTER2_HEATMAP_DIAGRAM: "/dashboard/india/diagonistic/inverter2/heatmap-diagram",


  INDIA_INVERTER3_SCB_SMB1: "/dashboard/india/diagonistic/inverter3/SCBSMB1",
  INDIA_INVERTER3_SCB_SMB2: "/dashboard/india/diagonistic/inverter3/SCBSMB2",
  INDIA_INVERTER3_SCB_SMB3: "/dashboard/india/diagonistic/inverter3/SCBSMB3",
  INDIA_INVERTER3_SCB_SMB4: "/dashboard/india/diagonistic/inverter3/SCBSMB4",
  INDIA_INVERTER3_SCB_SMB5: "/dashboard/india/diagonistic/inverter3/SCBSMB5",
  INDIA_INVERTER3_SCB_SMB6: "/dashboard/india/diagonistic/inverter3/SCBSMB6",
  INDIA_INVERTER3_SCB_SMB7: "/dashboard/india/diagonistic/inverter3/SCBSMB7",
  INDIA_INVERTER3_SCB_SMB8: "/dashboard/india/diagonistic/inverter3/SCBSMB8",
  INDIA_INVERTER3_SCB_SMB9: "/dashboard/india/diagonistic/inverter3/SCBSMB9",
  INDIA_INVERTER3_SCB_SMB10: "/dashboard/india/diagonistic/inverter3/SCBSMB10",
  INDIA_INVERTER3_SCB_SMB11: "/dashboard/india/diagonistic/inverter3/SCBSMB11",
  INDIA_INVERTER3_SCB_SMB12: "/dashboard/india/diagonistic/inverter3/SCBSMB12",
  INDIA_INVERTER3_HEATMAP_DIAGRAM: "/dashboard/india/diagonistic/inverter3/heatmap-diagram",


  INDIA_INVERTER4_SCB_SMB1: "/dashboard/india/diagonistic/inverter4/SCBSMB1",
  INDIA_INVERTER4_SCB_SMB2: "/dashboard/india/diagonistic/inverter4/SCBSMB2",
  INDIA_INVERTER4_SCB_SMB3: "/dashboard/india/diagonistic/inverter4/SCBSMB3",
  INDIA_INVERTER4_SCB_SMB4: "/dashboard/india/diagonistic/inverter4/SCBSMB4",
  INDIA_INVERTER4_SCB_SMB5: "/dashboard/india/diagonistic/inverter4/SCBSMB5",
  INDIA_INVERTER4_SCB_SMB6: "/dashboard/india/diagonistic/inverter4/SCBSMB6",
  INDIA_INVERTER4_SCB_SMB7: "/dashboard/india/diagonistic/inverter4/SCBSMB7",
  INDIA_INVERTER4_SCB_SMB8: "/dashboard/india/diagonistic/inverter4/SCBSMB8",
  INDIA_INVERTER4_SCB_SMB9: "/dashboard/india/diagonistic/inverter4/SCBSMB9",
  INDIA_INVERTER4_SCB_SMB10: "/dashboard/india/diagonistic/inverter4/SCBSMB10",
  INDIA_INVERTER4_SCB_SMB11: "/dashboard/india/diagonistic/inverter4/SCBSMB11",
  INDIA_INVERTER4_SCB_SMB12: "/dashboard/india/diagonistic/inverter4/SCBSMB12",
  INDIA_INVERTER4_HEATMAP_DIAGRAM: "/dashboard/india/diagonistic/inverter4/heatmap-diagram",


};

export default PageURL;
